<div class="sidebar">
    <!-- About Author Widget -->
    <div class="widget about-author-widget mb-40">
        <h5 class="widget-title">About Me</h5>
        <div class="author-box">
            <img src="assets/img/author.png" alt="author">
            <h6>Rosalina D. Willaimson</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt ut labore.</p>
            <ul class="social-icon">
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
            </ul>
        </div>
    </div>
    <!-- Search Widget -->
    <div class="widget search-widget mb-40">
        <h5 class="widget-title">Search Objects</h5>
        <form action="#">
            <input type="text" placeholder="Search your keyword...">
            <button type="submit"><i class="far fa-search"></i></button>
        </form>
    </div>
    <!-- Popular Post Widget -->
    <div class="widget popular-feeds mb-40">
        <h5 class="widget-title">Popular Feeds</h5>
        <div class="popular-feed-loop">
            <div class="single-popular-feed">
                <div class="feed-img">
                    <img src="assets/img/recent-post-wid/04.png" alt="Image">
                </div>
                <div class="feed-desc">
                    <h6><a routerLink='/blog-detail'>Lorem ipsum dolor sit cing elit, sed do.</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> 24th March 2020</span>
                </div>
            </div>
            <div class="single-popular-feed">
                <div class="feed-img">
                    <img src="assets/img/recent-post-wid/05.png" alt="Image">
                </div>
                <div class="feed-desc">
                    <h6><a routerLink='/blog-detail'>Lorem ipsum dolor sit cing elit, sed do.</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> 24th March 2020</span>
                </div>
            </div>
            <div class="single-popular-feed">
                <div class="feed-img">
                    <img src="assets/img/recent-post-wid/06.png" alt="Image">
                </div>
                <div class="feed-desc">
                    <h6><a routerLink='/blog-detail'>Lorem ipsum dolor sit cing elit, sed do.</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> 24th March 2020</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Categories Widget -->
    <div class="widget categories-widget mb-40">
        <h5 class="widget-title">Categories</h5>
        <ul>
            <li>
                <a routerLink='/blog-detail'>Business<span>26</span></a>
            </li>
            <li>
                <a routerLink='/blog-detail'>Consultant<span>26</span></a>
            </li>
            <li>
                <a routerLink='/blog-detail'>Creative<span>26</span></a>
            </li>
            <li>
                <a routerLink='/blog-detail'>UI/UX<span>26</span></a>
            </li>
            <li>
                <a routerLink='/blog-detail'>Technology<span>26</span></a>
            </li>
        </ul>
    </div>
    <!-- Social Icon Widget -->
    <div class="widget socail-widget mb-40">
        <h5 class="widget-title">Never Miss News</h5>
        <ul>
            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
            <li><a href="#"><i class="fab fa-behance"></i></a></li>
            <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
        </ul>
    </div>
    <!-- Twitter Feeds Widget -->
    <div class="widget twitter-feed-widget mb-40">
        <h5 class="widget-title">Twitter Feeds</h5>
        <ul>
            <li>
                <a routerLink='/blog-detail'>
                    Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @
                    ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1
                </a>
                <span class="date">November 25, 2018</span>
            </li>
            <li>
                <a routerLink='/blog-detail'>
                    Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @
                    ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1
                </a>
                <span class="date">November 25, 2018</span>
            </li>
            <li>
                <a routerLink='/blog-detail'>
                    Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @
                    ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1
                </a>
                <span class="date">November 25, 2018</span>
            </li>
        </ul>
    </div>
    <!-- Instagram Feeds Widget -->
    <div class="widget instagram-feed-widget mb-40">
        <h5 class="widget-title">Instagram Feeds</h5>
        <ul>
            <li><img src="assets/img/instagram/01.jpg" alt="Image"></li>
            <li><img src="assets/img/instagram/02.jpg" alt="Image"></li>
            <li><img src="assets/img/instagram/03.jpg" alt="Image"></li>
            <li><img src="assets/img/instagram/04.jpg" alt="Image"></li>
            <li><img src="assets/img/instagram/05.jpg" alt="Image"></li>
            <li><img src="assets/img/instagram/06.jpg" alt="Image"></li>
            <li><img src="assets/img/instagram/07.jpg" alt="Image"></li>
            <li><img src="assets/img/instagram/08.jpg" alt="Image"></li>
            <li><img src="assets/img/instagram/09.jpg" alt="Image"></li>
        </ul>
    </div>
    <!-- Popular Tags Widget -->
    <div class="widget popular-tag-widget mb-40">
        <h5 class="widget-title">Popular Tags</h5>
        <ul>
            <li><a routerLink='/blog-detail'>Popular</a></li>
            <li><a routerLink='/blog-detail'>design</a></li>
            <li><a routerLink='/blog-detail'>ux</a></li>
            <li><a routerLink='/blog-detail'>usability</a></li>
            <li><a routerLink='/blog-detail'>develop</a></li>
            <li><a routerLink='/blog-detail'>icon</a></li>
            <li><a routerLink='/blog-detail'>business</a></li>
            <li><a routerLink='/blog-detail'>consult</a></li>
            <li><a routerLink='/blog-detail'>kit</a></li>
            <li><a routerLink='/blog-detail'>keyboard</a></li>
            <li><a routerLink='/blog-detail'>mouse</a></li>
            <li><a routerLink='/blog-detail'>tech</a></li>
        </ul>
    </div>
    <!-- Banner Ad Widget -->
    <div class="widget banner-ad-widget">
        <img src="assets/img/banner-widget.jpg" alt="Image">
    </div>
</div>

