<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/87.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>web development</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>our services</li>
        </ul>
        <span class="btg-text">onitir</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== FEATURE SECTION START ======-->
<section class="features-boxes-two pt-180 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="features-boxes-loop">
                    <!-- Loop start -->
                    <div class="row fetaure-masonary">
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-box-two text-center">
                                <div class="icon text-center">
                                    <i class="fas fa-paper-plane"></i>
                                </div>
                                <h4>our mission</h4>
                                <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod
                                    tempor
                                </p>
                                <span class="count">01</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-box-two mt-30 text-center">
                                <div class="icon text-center">
                                    <i class="fas fa-globe"></i>
                                </div>
                                <h4>our vision</h4>
                                <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod
                                    tempor
                                </p>
                                <span class="count">02</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-box-two mt-30 text-center">
                                <div class="icon text-center">
                                    <i class="fas fa-users"></i>
                                </div>
                                <h4>our approch</h4>
                                <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod
                                    tempor
                                </p>
                                <span class="count">03</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-box-two mt-30 text-center">
                                <div class="icon text-center">
                                    <i class="fas fa-cogs"></i>
                                </div>
                                <h4>our strategy</h4>
                                <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod
                                    tempor
                                </p>
                                <span class="count">04</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class="feature-text pl-50">
                    <div class="section-title left-border mb-40">
                        <span class="title-tag">COLLABRATION WITH US</span>
                        <h2>Dedicated IT Solutions with 25 Years Experience.</h2>
                    </div>
                    <p>Over the years, a wide range of developments and innovations in the global IT arena have led
                        to many new IT-enabled devices and services being produced. Moreover, there is need for IT
                        today, not just in urban areas but rural regions as well.</p>
                    <ul class="feature-icon mt-40">
                        <li>
                            <img src="assets/img/services/icon-1.png" alt="Icon">
                            <h6>Date Research Analysis</h6>
                        </li>
                        <li>
                            <img src="assets/img/services/icon-3.png" alt="Icon">
                            <h6>Date Research Analysis</h6>
                        </li>
                    </ul>
                    <a routerLink='/services' class="main-btn btn-filled mt-40">Our Services</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== FEATURE SECTION END ======-->
<!--====== FRAMEWORK PART START ======-->
<section class="framework-section">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="framework-img">
                    <img src="assets/img/framework.jpg" alt="Image">
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class="framework-text pl-20">
                    <div class="section-title left-border mb-40">
                        <span class="title-tag">framework</span>
                        <h2> we are using best quality framwork for you. </h2>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade" id="python" role="tabpanel">
                            <p>
                                Over the years, a wide range of developments and innovations in the global IT arena
                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                is need for IT today, not just in urban areas but rural regions as well.
                            </p>
                        </div>
                        <div class="tab-pane fade show active" id="wordpress" role="tabpanel">
                            <p>
                                Over the years, a wide range of developments and innovations in the global IT arena
                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                is need for IT today, not just in urban areas but rural regions as well.
                            </p>
                        </div>
                        <div class="tab-pane fade" id="html" role="tabpanel">
                            <p>
                                Over the years, a wide range of developments and innovations in the global IT arena
                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                is need for IT today, not just in urban areas but rural regions as well.
                            </p>
                        </div>
                        <div class="tab-pane fade" id="java" role="tabpanel">
                            <p>
                                Over the years, a wide range of developments and innovations in the global IT arena
                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                is need for IT today, not just in urban areas but rural regions as well.
                            </p>
                        </div>
                    </div>
                    <ul class="framework-list nav nav-pills mt-25" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" id="pills-python-tab" data-toggle="pill" href="#python">
                                <span class="icon">
                                    <i class="fab fa-python"></i>
                                </span>
                                Python
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-wordpress-tab" data-toggle="pill"
                                href="#wordpress">
                                <span class="icon">
                                    <i class="fab fa-wordpress"></i>
                                </span>
                                Wordpress
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-html-tab" data-toggle="pill" href="#html">
                                <span class="icon">
                                    <i class="fab fa-html5"></i>
                                </span>
                                HTML5
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#java" role="tab">
                                <span class="icon">
                                    <i class="fab fa-java"></i>
                                </span>
                                JAVA
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== FRAMEWORK PART end ======-->
<!--====== TESTIMONIALS & clinet SECTION ======-->
<section class="testimonials-clinet-section pt-120 pb-120">
    <div class="container">
        <div class="section-title both-border text-center pb-80">
            <span class="title-tag">Testimonials</span>
            <h2>Client Feedbacks</h2>
        </div>
        <div class="testimonials-section pb-125">
            <div class="testimonials-slider-two row">
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/01.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/02.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/03.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/01.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/02.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/03.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="clinet-section">
        <div class="container-fluid custom-container-one">
            <div class="clinet-slider">
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/01.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/02.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/03.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/04.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/05.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/01.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/02.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/03.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/04.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/05.png" alt="Image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== TESTIMONIALS & BRAND SECTION END ======-->
<!--====== LATEST POST START ======-->
<section class="latetest-post pt-120 pb-90">
    <div class="container">
        <!-- Section  Title -->
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-8 col-sm-7 col-6">
                <div class="section-title left-border">
                    <span class="title-tag">Blog</span>
                    <h2>news feeds</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-5 col-6">
                <div class="blog-btn text-right">
                    <a routerLink='/blog' class="main-btn btn-filled">learn more</a>
                </div>
            </div>
        </div>
        <!-- post Loop -->
        <div class="post-loop mt-70">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-12 col-sm-10 order-2 order-lg-1">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Software</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-10 col-sm-10 order-1 order-lg-2">
                    <div class="post-grid-box mb-30">
                        <ul class="post-cat">
                            <li><a routerLink='/blog-detail'>Business</a></li>
                        </ul>
                        <div class="post-desc">
                            <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the first
                                    screen has instructions.</a></h4>
                            <p>Too much data and forcing the brain to accommodate all these data frustrate the user
                                and leads them to abandon the task. The short term memory of our brain has
                                limitations.</p>
                        </div>
                        <ul class="post-meta">
                            <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                            <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== LATEST POST END ======-->
<app-footer3></app-footer3>
