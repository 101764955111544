<app-header3></app-header3>
<!--====== BANNER SECTION START ======-->
<section class="banner-section banner-style-three banner-slider-two"
style="background-image: url(assets/img/home_banner.jpg);">
<!-- <div class="slider-active" id="bannerSliderTwo">
    <div class="single-banner">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="banner-text">
                        <h1 data-animation="fadeInUp" data-delay=".7s" style="animation-delay: 0.7s;">We Create
                            <span>Perfect</span> Brand Goods <span>Value</span></h1>
                        <div class="btn-wrap" data-animation="fadeInUp" data-delay=".9s">
                            <a routerLink='/contact-us' class="main-btn btn-filled">Get Started Now</a>
                            <a routerLink='/about' class="main-btn btn-borderd">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="single-banner">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="banner-text">
                        <h1 data-animation="fadeInUp" data-delay=".7s" style="animation-delay: 0.7s;">We Create
                            <span>Perfect</span> Brand Goods <span>Value</span></h1>
                        <div class="btn-wrap" data-animation="fadeInUp" data-delay=".9s">
                            <a routerLink='/contact-us' class="main-btn btn-filled">Get Started Now</a>
                            <a routerLink='/about' class="main-btn btn-borderd">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="single-banner">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="banner-text">
                        <h1 data-animation="fadeInUp" data-delay=".7s" style="animation-delay: 0.7s;">We Create
                            <span>Perfect</span> Brand Goods <span>Value</span></h1>
                        <div class="btn-wrap" data-animation="fadeInUp" data-delay=".9s">
                            <a routerLink='/contact-us' class="main-btn btn-filled">Get Started Now</a>
                            <a routerLink='/about' class="main-btn btn-borderd">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="banner-shape-three">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
        <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
        </path>
    </svg>
</div>
</section>
<!--====== BANNER SECTION END ======-->
<!--======  FEATURED SERVICES SECTION START ======-->
<section class="services-secton featured-service mt-negative">
<div class="container">
    <div class="services-loop">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="single-service text-center white-bg mt-60">
                    <div class="icon">
                        <img src="assets/img/services/icon-1.png" alt="Icon">
                    </div>
                    <h4>Software Training</h4>
                    <!-- <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                        incididunt ut labore.</p>
                    <a routerLink='/service-detail' class="service-link">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="single-service text-center secondary-bg doted mt-60">
                    <div class="icon">
                        <img src="assets/img/services/icon-2.png" alt="Icon">
                    </div>
                    <h4>App Development</h4>
                    <!-- <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                        incididunt ut labore.</p>
                    <a routerLink='/service-detail' class="service-link">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="single-service text-center primary-bg mt-60">
                    <div class="icon">
                        <img src="assets/img/services/icon-3.png" alt="Icon">
                    </div>
                    <h4>It Consulting Services</h4>
                    <!-- <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                        incididunt ut labore.</p>
                    <a routerLink='/service-detail' class="service-link">Read More</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<!--====== FEATURED SERVICES SECTION END ======-->
<!--====== ABOUT SECTION START ======-->
<section class="about-section about-style-three pt-120 pb-120">
<div class="container">
    <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 col-md-10">
            <div class="about-text pr-30">
                <div class="section-title left-border mb-40">
                    <span class="title-tag">About us</span>
                    <h2>Tek Assets help businesses elevate their value.</h2>
                </div>
                <p>
                    Over the years, a wide range of developments and innovations in the global IT
                    arena have led to many new IT-enabled devices and services being produced. Moreover, there
                    is need for IT today, not just in urban areas but rural regions as well.
                </p>
                <!-- <div class="experience-tag mt-40">
                    <img src="assets/img/experience-tag.png" alt="Image">
                </div> -->
            </div>
        </div>
        <div class="col-lg-6 col-md-10 order-first order-lg-last">
            <div class="about-img">
                <img src="assets/img/markus-spiske-Skf7HxARcoc-unsplash.jpg" alt="Image">
            </div>
        </div>
    </div>
</div>
</section>
<!--====== ABOUT SECTION END ======-->
<!--====== SERVICES SECTION START ======-->
<section class="services-secton services-secton-two pt-120 pb-120">
<div class="container">
    <!-- Section Title -->
    <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-8 col-sm-7">
            <div class="section-title left-border">
                <span class="title-tag">our services</span>
                <h2>see what we do here with good passions</h2>
            </div>
        </div>
        <div class="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
            <div class="service-page-link- text-right">
                <!-- <a routerLink='/services' class="main-btn btn-filled">learn more</a> -->
            </div>
        </div>
    </div>
    <div class="services-loop mt-50">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-service-two">
                    
                    <h4>Training & Placement</h4>
                    
                    <div class="bottom-icon">
                        <i class="pe-7s-world"></i>
                    </div>
                    <br/>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-service-two">
                    <h4>Industry Standard Certifications</h4>
                    
                    <div class="bottom-icon">
                        <i class="pe-7s-target"></i>
                    </div>
                    <br/>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-service-two">
                    <h4>Agile Transformation Implementation</h4>
                    <div class="bottom-icon">
                        <i class="pe-7s-science"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-service-two">
                    <h4>Application Development</h4>
                    
                    <div class="bottom-icon">
                        <i class="pe-7s-target"></i>
                    </div>
                    <br/>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-service-two">
                    <div class="top-icon">
                        <i class="fal fa-long-arrow-right"></i>
                    </div>
                    <h4>IT Support</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incid.
                    </p>
                    <div class="bottom-icon">
                        <i class="pe-7s-help2"></i>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
</section>
<!--====== SERVICES SECTION END ======-->
<!--====== PORTFOLIO START ======-->
<!-- <section class="portfolio-slider-section pt-120 pb-120">
<div class="container-fluid"> -->
    <!-- portfolio loop -->
    <!-- <div class="row portfolio-slider-loop portfolio-slider">
        <div class="col-12">
            <div class="portfolio-box">
                <div class="portfolio-img-wrap">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/23.jpg);">
                    </div>
                </div>
                <div class="portfolio-desc">
                    <span class="portfolio-cat">ui/ux design, mobile app</span>
                    <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                    <a routerLink='/portfolio-detail' class="portfolio-link">
                        <i class="fal fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="portfolio-box">
                <div class="portfolio-img-wrap">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/24.jpg);">
                    </div>
                </div>
                <div class="portfolio-desc">
                    <span class="portfolio-cat">ui/ux design, mobile app</span>
                    <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                    <a routerLink='/portfolio-detail' class="portfolio-link">
                        <i class="fal fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="portfolio-box">
                <div class="portfolio-img-wrap">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/25.jpg);">
                    </div>
                </div>
                <div class="portfolio-desc">
                    <span class="portfolio-cat">ui/ux design, mobile app</span>
                    <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                    <a routerLink='/portfolio-detail' class="portfolio-link">
                        <i class="fal fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="portfolio-box">
                <div class="portfolio-img-wrap">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/23.jpg);">
                    </div>
                </div>
                <div class="portfolio-desc">
                    <span class="portfolio-cat">ui/ux design, mobile app</span>
                    <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                    <a routerLink='/portfolio-detail' class="portfolio-link">
                        <i class="fal fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="portfolio-box">
                <div class="portfolio-img-wrap">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/24.jpg);">
                    </div>
                </div>
                <div class="portfolio-desc">
                    <span class="portfolio-cat">ui/ux design, mobile app</span>
                    <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                    <a routerLink='/portfolio-detail' class="portfolio-link">
                        <i class="fal fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="portfolio-box">
                <div class="portfolio-img-wrap">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/25.jpg);">
                    </div>
                </div>
                <div class="portfolio-desc">
                    <span class="portfolio-cat">ui/ux design, mobile app</span>
                    <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                    <a routerLink='/portfolio-detail' class="portfolio-link">
                        <i class="fal fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</section> -->
<!--====== PORTFOLIO END ======-->
<!--====== CALL TO ACTION START ======-->
<!-- <section class="call-to-action cta-style-two">
<div class="container">
    <div class="cta-inner">
        <div class="cat-img">
            <div class="cat-shape shape-two">
                <img src="assets/img/cta/cta-img-2.png" alt="Image">
            </div>
        </div>
        <div class="cta-text diff-bg">
            <div class="row align-items-center justify-content-end">
                <div class="col-lg-6 col-md-7 col-sm-10">
                    <div class="section-title pl-30 left-border">
                        <span class="title-tag">need consultation</span>
                        <h2>We are here for your estimate price.</h2>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-2 col-md-2 col-sm-2">
                    <div class="link-wrap text-center">
                        <a routerLink='/contact-us' class="cat-link"><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section> -->
<!--====== CALL TO ACTION END ======-->
<!--====== TEAM SECTION START ======-->
<!-- <section class="team-section pt-120 pb-90">
<div class="container">
    <div class="section-title both-border text-center mb-80">
        <span class="title-tag">Team</span>
        <h2>Our Team Members</h2>
    </div>
    <div class="row team-loop team-slider-two">
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/01.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>Rosa D.William</h3>
                    <span>Founder & CEO</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/02.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>hilixer b. browni</h3>
                    <span>co-founder</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/03.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>pokoloko k. kilix</h3>
                    <span>consultant</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/04.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>pokoloko k. kilix</h3>
                    <span>consultant</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/05.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>pokoloko k. kilix</h3>
                    <span>consultant</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/01.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>Rosa D.William</h3>
                    <span>Founder & CEO</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/02.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>hilixer b. browni</h3>
                    <span>co-founder</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/03.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>pokoloko k. kilix</h3>
                    <span>consultant</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="member-box">
                <div class="member-img">
                    <img src="assets/img/team/04.jpg" alt="Team-Image">
                </div>
                <div class="member-info">
                    <h3>pokoloko k. kilix</h3>
                    <span>consultant</span>
                </div>
                <a href="#" class="socail-trigger">+</a>
            </div>
        </div>
    </div>
</div>
</section> -->
<!--====== TEAM SECTION END ======-->
<!--====== VIDEO SECTION START ======-->
<!-- <section class="video-section video-style-two" style="background-image: url(assets/img/video-bg-2.jpg);">
<div class="container">
    <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8 col-md-10">
            <div class="video-text text-center">
                <div class="video-link-two">
                    <a href="#" class="popup-video"><i class="fas fa-play"></i></a>
                </div>
                <p>These values give us the foundations we need</p>
                <h1>our values is only hard working</h1>
            </div>
        </div>
    </div>
</div>
</section> -->
<!--====== VIDEO SECTION END ======-->
<!--====== CONTACT SECTION START ======-->
<section class="contact-section pt-110 pb-110">
<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-7 col-sm-7">
            <div class="section-title left-border">
                <span class="title-tag">Get In Touch</span>
                <h2>Get Catch us from here. right now</h2>
            </div>
        </div>
        <div class="col-lg-6 col-md-5 col-sm-5 d-none d-sm-block">
            <div class="contact-page-link text-right">
                <a routerLink='/contact-us' class="main-btn btn-filled">get direction</a>
            </div>
        </div>
    </div>
    <div class="contact-form-wrapper mt-80 mb-110">
        <div class="row no-gutters align-items-center align-items-lg-start justify-content-center">
            <div class="col-lg-5 col-md-5">
                <div class="contact-info-list">
                    <div class="info-box">
                        <div class="icon">
                            <i class="pe-7s-home"></i>
                        </div>
                        <div class="content">
                            <h4>Office Address</h4>
                            <p>44355 premier plaza, suite 120
                            <br>Ashburn, VA 20147 </p>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="icon">
                            <i class="pe-7s-call"></i>
                        </div>
                        <div class="content">
                            <h4>Phone Number</h4>
                            <p>(240)642-1085‬ <br></p>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="icon">
                            <i class="pe-7s-comment"></i>
                        </div>
                        <div class="content">
                            <h4>Email address</h4>
                            <p>hr@tek-assets.com<br></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-7">
                <div class="contact-form">
                    <form action="#">
                        <div class="row padding-custom">
                            <div class="col-12 col-lg-6">
                                <div class="input-group mb-10">
                                    <div class="icon">
                                        <i class="fal fa-user"></i>
                                    </div>
                                    <input type="text" placeholder="Your name">
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="input-group mb-10">
                                    <div class="icon">
                                        <i class="fal fa-envelope"></i>
                                    </div>
                                    <input type="text" placeholder="Your email">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="input-group mb-10">
                                    <div class="icon">
                                        <i class="fal fa-envelope"></i>
                                    </div>
                                    <input type="text" placeholder="Your email">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="input-group textarea mb-10">
                                    <div class="icon">
                                        <i class="fal fa-edit"></i>
                                    </div>
                                    <textarea placeholder="Enter message"></textarea>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <button type="submit" class="main-btn btn-filled">Get A QUote</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="clinet-section">
    <div class="container-fluid custom-width-three">
        <div class="clinet-slider">
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/01.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/02.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/03.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/04.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/05.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/01.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/02.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/03.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/04.png" alt="Image">
                </a>
            </div>
            <div class="clinet-item">
                <a href="#">
                    <img src="assets/img/brand/05.png" alt="Image">
                </a>
            </div>
        </div>
    </div>
</div> -->
</section>
<!--====== CONTACT SECTION END ======-->
<app-footer3></app-footer3>
