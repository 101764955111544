<!-- Page Sidebar -->
<div class="sidebar">
    <!-- Service cat -->
    <div class="widget service-cat-widget mb-40">
        <h5 class="widget-title">Category</h5>
        <ul>
            <li><a routerLink='/service-detail'>IT Consultancy</a></li>
            <li><a routerLink='/service-detail'>Digital Services</a></li>
            <li><a routerLink='/service-detail'>Advisory Services</a></li>
            <li><a routerLink='/service-detail'>Data Structuring</a></li>
            <li><a routerLink='/service-detail'>Experience Design</a></li>
            <li><a routerLink='/service-detail'>Content Engineering</a></li>
        </ul>
    </div>
    <!-- Brouchers widget -->
    <div class="widget brouchers-widget mb-40">
        <h5 class="widget-title">Brouchers</h5>
        <ul>
            <li><a routerLink='/service-detail'><i class="fas fa-file-pdf"></i>DOWNLOAD PDF FILE</a></li>
            <li><a routerLink='/service-detail'><i class="fas fa-file-powerpoint"></i>OUR ISO CERTIFICATES</a></li>
            <li><a routerLink='/service-detail'><i class="fas fa-file-image"></i>OUR ISO CERTIFICATES</a></li>
        </ul>
    </div>
    <!-- Contact Widget -->
    <div class="widget contact-widget mb-40">
        <h5 class="widget-title">Contact Us</h5>
        <form action="#">
            <div class="input-group">
                <span class="icon"><i class="fas fa-user"></i></span>
                <input type="text" placeholder="Enter Your Name">
            </div>
            <div class="input-group">
                <span class="icon"><i class="fas fa-envelope"></i></span>
                <input type="email" placeholder="Enter email">
            </div>
            <div class="input-group textarea">
                <span class="icon"><i class="fas fa-edit"></i></span>
                <textarea placeholder="Enter message"></textarea>
            </div>
            <div class="text-center mt-20"><button type="submit" class="main-btn btn-filled">Get A
                    QUote</button></div>
        </form>
    </div>
    <!-- Bannner Widget -->
    <div class="widget banner-ad-widget">
        <img src="assets/img/banner-widget-2.jpg" alt="Image">
    </div>
</div>

