import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolioslider',
  templateUrl: './portfolioslider.component.html',
  styleUrls: ['./portfolioslider.component.css']
})
export class PortfoliosliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
