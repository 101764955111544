<!--====== GO TO TOP PART START ======-->
<div class="go-top-area">
    <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
            <div class="go-top go-top-btn">
                <i class="fal fa-angle-double-up"></i>
                <i class="fal fa-angle-double-up"></i>
            </div>
        </div>
    </div>
</div>
<!--====== GO TO TOP PART ENDS ======-->
<!--====== FOOTER PART START ======-->
<footer class="footer-style-two">
    <div class="footer-widget-area">
        <div class="container">
            <div class="foter-logo text-center">
                <img src="assets/img/logo-white.png" alt="Onitir">
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6 order-1">
                    <div class="widget about-widget">
                        <h3 class="widget-title">About Us</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8 order-3 order-lg-2">
                    <div class="widget getin-widget">
                        <h3 class="widget-title">Get In touch</h3>
                        <ul>
                            <li>+987 876 765 87 67 6</li>
                            <li>info@webexample.com</li>
                            <li>14/Browni City Tower Hall <br> New York, US </li>
                        </ul>
                        <a href="#" class="getin-btn">Get Direction</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 order-2 order-lg-3">
                    <div class="widget nav-widget">
                        <h3 class="widget-title">What We Do</h3>
                        <ul>
                            <li><a href="#">Web & Mobile Apps</a></li>
                            <li><a href="#">Website Hosting</a></li>
                            <li><a href="#">Big Data</a></li>
                            <li><a href="#">Managed Services</a></li>
                            <li><a href="#">Business Solutions</a></li>
                            <li><a href="#">Research Program</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copy-right-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7">
                    <div class="social-icon text-center text-md-right">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-behance"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                        <a href="#"><i class="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--====== FOOTER PART END ======-->
