<!--====== PRELOADER ======-->
<div id="preloader">
    <div>
        <div></div>
    </div>
</div>
<!--====== HEADER START ======-->
<header class="header-absolute header-three sticky-header">
    <div class="container-fluid custom-container-two">
        <div class="mainmenu-area">
            <div class="d-flex align-items-center justify-content-between">
                <nav class="main-menu">
                    <div class="logo active">
                        <a routerLink=''>
                          <!-- <h3>Beyond Agility Solutions</h3> -->
                          <img src="assets/img/bas_logo_white.png" alt="Tek Assets" class="normal-logo">
                          <img src="assets/img/bas_logo.png" alt="Tek Assets" class="sticky-logo">
                        </a>
                    </div>
                    <div class="menu-items">
                        <ul>
                            <li>
                                <a routerLink='' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
                                <!-- <ul class="submenu">
                                    <li><a routerLink='' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>
                                    <li><a routerLink='/homepage-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>
                                    <li><a routerLink='/homepage-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                                </ul> -->
                            </li>
<!--                            <li>-->
<!--                                <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>-->
<!--                            </li>-->
                            <li>
                                <a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a>
                                <ul class="submenu">
                                    <!-- <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                                    <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Details</a></li> -->
                                </ul>
                            </li>
<!--                            <li>-->
<!--                                <a href="javascript:void(0)">Pages</a>-->
<!--                                <ul class="submenu">-->
<!--                                    <li>-->
<!--                                        <a routerLink='/why-choose-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Why Choose Us</a>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="javascript:void(0)">Team</a>-->
<!--                                        <ul class="submenu">-->
<!--                                            <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>-->
<!--                                            <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team Details</a></li>-->
<!--                                        </ul>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="javascript:void(0)">Portfolio</a>-->
<!--                                        <ul class="submenu">-->
<!--                                            <li><a routerLink='/portfolio-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Grid</a></li>-->
<!--                                            <li><a routerLink='/portfolio-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Masonary</a></li>-->
<!--                                            <li><a routerLink='/portfolio-slider' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Slider</a></li>-->
<!--                                            <li><a routerLink='/portfolio-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Details</a></li>-->
<!--                                        </ul>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="javascript:void(0)">News</a>-->
<!--                                        <ul class="submenu">-->
<!--                                            <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Standard</a> </li>-->
<!--                                            <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Grid</a> </li>-->
<!--                                            <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Details</a></li>-->
<!--                                        </ul>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a routerLink='/faqs' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="javascript:void(0)">News</a>-->
<!--                                <ul class="submenu">-->
<!--                                    <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Standard</a> </li>-->
<!--                                    <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Grid</a> </li>-->
<!--                                    <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Details</a></li>-->
<!--                                </ul>-->
<!--                            </li>-->
                            <li>
                                <a routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div class="main-right">
<!--                    <a routerLink='/contact-us' class="main-btn btn-filled">-->
<!--                        Get A Quote-->
<!--                    </a>-->
                    <a href="#" class="offcanvas-trigger">
                        <i class="far fa-ellipsis-v"></i>
                    </a>
                    <!-- OFF CANVAS WRAP START -->
                    <div class="off-canvas-wrap">
                        <div class="overly"></div>
                        <div class="off-canvas-widget">
                            <a href="#" class="off-canvas-close"><i class="fal fa-times"></i></a>
<!--                            <div class="widget recent-post">-->
<!--                                <h4 class="widget-title">Recent Posts</h4>-->
<!--                                <ul>-->
<!--                                    <li>-->
<!--                                        <div class="post-img"-->
<!--                                            style="background-image: url(assets/img/laest-post/01.jpg);">-->
<!--                                        </div>-->
<!--                                        <div class="post-content">-->
<!--                                            <h6>-->
<!--                                                <a routerLink='/blog-detail'>Signs moved moveth itself on years whose-->
<!--                                                    without.</a>-->
<!--                                            </h6>-->
<!--                                            <span class="time"><i class="far fa-clock"></i>4 May.2020</span>-->
<!--                                        </div>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <div class="post-img"-->
<!--                                            style="background-image: url(assets/img/laest-post/02.jpg);">-->
<!--                                        </div>-->
<!--                                        <div class="post-content">-->
<!--                                            <h6>-->
<!--                                                <a routerLink='/blog-detail'>Under brought airte them saw stars-->
<!--                                                    created seas.</a>-->
<!--                                            </h6>-->
<!--                                            <span class="time"><i class="far fa-clock"></i>4 May.2020</span>-->
<!--                                        </div>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <div class="post-img"-->
<!--                                            style="background-image: url(assets/img/laest-post/03.jpg);">-->
<!--                                        </div>-->
<!--                                        <div class="post-content">-->
<!--                                            <h6>-->
<!--                                                <a routerLink='/blog-detail'>Our form there seas set forth fill-->
<!--                                                    bearing.</a>-->
<!--                                            </h6>-->
<!--                                            <span class="time"><i class="far fa-clock"></i>4 May.2020</span>-->
<!--                                        </div>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
                            <div class="widget contact-widget">
                                <h4 class="widget-title">Contact Us</h4>
                                <ul>
                                    <li>
                                        <i class="far fa-map-marker-alt"></i>
                                        	44355 premier plaza, suite 120 
						Ashburn, VA 20147
                                    </li>
                                    <li>
                                        <i class="far fa-phone"></i>
                                        <a href="#">(240)642-1085</a>
                                    </li>
                                    <li>
                                        <i class="far fa-envelope-open"></i>
                                        <a href="#">hr@tek-assets.com</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="widget social-widget">
                                <h4 class="widget-title">Follow Us</h4>
                                <ul>
                                    <li>
                                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a href="#"><i class="fab fa-twitter"></i></a>
                                        <a href="#"><i class="fab fa-instagram"></i></a>
                                        <a href="#"><i class="fab fa-behance"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- OFF CANVAS WRAP END -->
                </div>
            </div>
        </div>
        <!-- Mobile Menu -->
        <div class="row">
            <div class="col-12">
                <div class="mobile-menu"></div>
            </div>
        </div>
    </div>
</header>
<!--====== HEADER END ======-->

