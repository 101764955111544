<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/breadcrumb.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>Portfolio Details</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>Portfolio Details</li>
        </ul>
        <span class="btg-text">onitir</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== PORTFOLIO DETAILS SART ======-->
<section class="portfolio-details-wrap pt-150 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="portfolio-thumb mb-60"
                    style="background-image: url(assets/img/details/portfolio-thumb.jpg);">
                    <div class="portfolio-info">
                        <ul>
                            <li>
                                <span class="title">Project Name</span>
                                <p>Miranda Consultancy App</p>
                            </li>
                            <li>
                                <span class="title">Client Name</span>
                                <p>Miranda H. Halim</p>
                            </li>
                            <li>
                                <span class="title">Date</span>
                                <p>30 December 2020</p>
                            </li>
                            <li>
                                <span class="title">Tag</span>
                                <p><a href="#">App,</a> <a href="#"> Software,</a> <a href="#"> Website</a></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="portfolio-details">
                    <div class="section-title left-border mb-40">
                        <span class="title-tag">it consultancy</span>
                        <h2>Dedicated IT Solutions with 25 Years Experience in this field</h2>
                    </div>
                    <p class="mb-30">
                        Once a business owner defined the needs to take a business to the next level, a decision
                        maker will define a scope, cost and a time frame of the project.[1] The role of the IT
                        consultancy company is to support and nurture the company from the very beginning of the
                        project until the end, and deliver the project not only in the scope, time and cost but also
                        with complete customer satisfaction.
                    </p>
                    <p>
                        The scope of a project is linked intimately to the proposed business processes and systems
                        that the project is going to deliver. Regardless of whether the project is to launch a new
                        product range or discontinue unprofitable parts of the business, the change will have some
                        impact on business processes and systems. The documentation of your business processes and
                        system requirements are as fundamental to project scoping as an architects plans would be to
                        the costing and scoping of the construction of a building. The most successful business
                        projects are always those that are driven by an employee who has the authority, vision and
                        influence to drive the required changes in a business. It is highly unlikely that a business
                        owner (decision maker or similar) will realize the changes unless one has one of these
                        people in the employment. However, the project leadership role typically requires
                        significant experience and skills which are not usually found within a company focused on
                        day-to-day operations. Due to this requirement within more significant business change
                        projects/programs, outside expertise is often sought from firms which can bring this
                        specific skill set to the company.
                    </p>
                    <div class="gallery mb-30 mt-60">
                        <div class="row">
                            <div class="col-sm-6 mb-30">
                                <img src="assets/img/details/03.jpg" alt="Image">
                            </div>
                            <div class="col-sm-6 mb-30">
                                <img src="assets/img/details/04.jpg" alt="Image">
                            </div>
                        </div>
                    </div>
                    <p class="mb-60">
                        The scope of a project is linked intimately to the proposed business processes and systems
                        that the project is going to deliver. Regardless of whether the project is to launch a new
                        product range or discontinue unprofitable parts of the business, the change will have some
                        impact on business processes and systems. The documentation of your business processes and
                        system requirements are as fundamental to project scoping as an architects plans would be to
                        the costing and scoping of the construction of a building. The most successful business
                        projects are always those that are driven by an employee who has the authority, vision and
                        influence to drive the required changes in a business.
                    </p>
                    <blockquote>
                        <span><img src="assets/img/details/avatar.png" alt="Image">by Hetmayar</span>
                        Software development outsourcing is just a tool to achieve business goals. But there is no
                        way to get worthwhile results without cooperation and trust.
                    </blockquote>
                    <p class="mt-60">
                        The scope of a project is linked intimately to the proposed business processes and systems
                        that the project is going to deliver. Regardless of whether the project is to launch a new
                        product range or discontinue unprofitable parts of the business, the change will have some
                        impact on business processes and systems. The documentation of your business processes and
                        system requirements are as fundamental to project scoping as an architects plans would be to
                        the costing and scoping of the construction of a building. The most successful business
                        projects are always those that are driven by an employee who has the authority, vision and
                        influence to drive the required changes in a business. It is highly unlikely that a business
                        owner (decision maker or similar) will realize the changes unless one has one of these
                        people in the employment. However, the project leadership role typically requires
                        significant experience and skills which are not usually found within a company focused on
                        day-to-day operations. Due to this requirement within more significant business change
                        projects/programs, outside expertise is often sought from firms which can bring this
                        specific skill set to the company.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-10">
                <!-- Page Sidebar -->
                <app-servicesidebar></app-servicesidebar>
            </div>
        </div>
    </div>
</section>
<app-footer3></app-footer3>