<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/breadcrumb.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>Portfolio masonary</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>Portfolio masonary</li>
        </ul>
        <span class="btg-text">onitir</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== PORTFOLIO START ======-->
<section class="portfolio-section pt-120 pb-90">
    <div class="container">
        <!-- portfolio filter -->
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="portfolio-filter masonry-filter">
                    <ul>
                        <li class="active" data-filter="*">All works</li>
                        <li data-filter=".item-one">it solution</li>
                        <li data-filter=".item-two">consultancy</li>
                        <li data-filter=".item-three">ux design</li>
                        <li data-filter=".item-four">web design</li>
                        <li data-filter=".item-five">seo</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- portfolio loop -->
        <div class="row portfolio-masonary-loop masonry-items">
            <div class="masonry-item col-lg-4 col-sm-6 item-one tem-four item-three">
                <div class="portfolio-box">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/17.jpg);"></div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='/portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="masonry-item col-lg-4 col-sm-6 item-three item-one item-four">
                <div class="portfolio-box">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/18.jpg);"></div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='/portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="masonry-item col-lg-8 col-sm-12">
                <div class="portfolio-box double-height">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/22.jpg);"></div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='/portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="masonry-item col-lg-4 col-sm-6 item-four item-one item-two">
                <div class="portfolio-box">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/19.jpg);"></div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='/portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="masonry-item col-lg-4 col-sm-6 item-five item-one item-two">
                <div class="portfolio-box">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/20.jpg);"></div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='/portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="masonry-item col-lg-4 col-sm-6 item-one item-two item-three">
                <div class="portfolio-box">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/21.jpg);"></div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='/portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== PORTFOLIO END ======-->
<!--====== CALL TO ACTION START ======-->
<section class="call-to-action cta-style-two mb-120">
    <div class="container">
        <div class="cta-inner">
            <div class="cat-img">
                <div class="cat-shape shape-two">
                    <img src="assets/img/cta/cta-img-2.png" alt="Image">
                </div>
            </div>
            <div class="cta-text diff-bg">
                <div class="row align-items-center justify-content-end">
                    <div class="col-lg-6 col-md-7 col-sm-10">
                        <div class="section-title pl-30 left-border">
                            <span class="title-tag">need consultation</span>
                            <h2>We are here for your estimate price.</h2>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-2 col-md-2 col-sm-2">
                        <div class="link-wrap text-center">
                            <a routerLink='/contact-us' class="cat-link"><i class="fal fa-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== CALL TO ACTION END ======-->
<app-footer3></app-footer3>