<app-header2></app-header2>
<!--====== BANNER SECTION START ======-->
<section class="banner-section banner-style-two">
    <div class="single-banner">
        <div class="container-fluid custom-container-two">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="banner-img">
                        <img src="assets/img/banner/circle-icon.png" alt="Icon">
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="banner-text">
                        <a href="#" class="video-icon popup-video"><i class="fas fa-play"></i></a>
                        <h1>We Create Perfect Brand Goods Value</h1>
                        <p>Bring your ideas to life with us</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== BANNER SECTION END ======-->
<!--====== ABOUT SECTION START ======-->
<section class="about-section about-style-two">
    <div class="about-iconic-boxes">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="iconic-box">
                        <h4>Machine Learning</h4>
                        <p>Get regular support from our team</p>
                        <span class="icon"><i class="fal fa-check"></i></span>
                        <span class="count">01</span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="iconic-box">
                        <h4>Artificial Intelligence</h4>
                        <p>Get regular support from our team</p>
                        <span class="icon"><i class="fal fa-check"></i></span>
                        <span class="count">02</span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <div class="iconic-box">
                        <h4>Augmented Reality</h4>
                        <p>Get regular support from our team</p>
                        <span class="icon"><i class="fal fa-check"></i></span>
                        <span class="count">03</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About Text -->
    <div class="about-text-warp">
        <div class="container">
            <div class="row no-gutters justify-content-center justify-content-lg-start">
                <div class="col-lg-5 col-md-10">
                    <div class="about-text position-relative">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">About us</span>
                            <h2>Beyond Agility Solutions help businesses elevate their value.</h2>
                        </div>
                        <p class="mb-30">Over the years, a wide range of developments and innovations in the global
                            IT
                            arena have led to many new IT-enabled devices and services being produced. Moreover,
                            there
                            is need for IT today, not just in urban areas but rural regions as well.</p>
                        <p>Entrepreneurs, artist, athletes and other individuals who have excelled in their field
                            share
                            fun and inspiring stories from their journey to success. Individuals who have excelled
                            in
                            their field share.</p>
                        <a routerLink='/about' class="main-btn btn-filled mt-40">Get Started Now</a>

                    </div>
                </div>
            </div>
        </div>
        <div class="mockup-img">
            <img src="assets/img/about/about-mock.png" alt="mockup-img">
        </div>
        <div class="about-small-img">
            <img src="assets/img/about/about.jpg" alt="about-small-img">
        </div>
    </div>
</section>
<!--====== ABOUT SECTION END ======-->
<!--====== FEATURE SECTION START ======-->
<section class="features-boxes-two pt-120 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="features-boxes-loop">
                    <!-- Loop start -->
                    <div class="row fetaure-masonary">
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-box-two text-center">
                                <div class="icon text-center">
                                    <i class="fas fa-paper-plane"></i>
                                </div>
                                <h4>our mission</h4>
                                <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod
                                    tempor
                                </p>
                                <span class="count">01</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-box-two mt-30 text-center">
                                <div class="icon text-center">
                                    <i class="fas fa-globe"></i>
                                </div>
                                <h4>our vision</h4>
                                <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod
                                    tempor
                                </p>
                                <span class="count">02</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-box-two mt-30 text-center">
                                <div class="icon text-center">
                                    <i class="fas fa-users"></i>
                                </div>
                                <h4>our approch</h4>
                                <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod
                                    tempor
                                </p>
                                <span class="count">03</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-box-two mt-30 text-center">
                                <div class="icon text-center">
                                    <i class="fas fa-cogs"></i>
                                </div>
                                <h4>our strategy</h4>
                                <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod
                                    tempor
                                </p>
                                <span class="count">04</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class="feature-text pl-50">
                    <div class="section-title left-border mb-40">
                        <span class="title-tag">COLLABRATION WITH US</span>
                        <h2>Dedicated IT Solutions with 25 Years Experience.</h2>
                    </div>
                    <p>Over the years, a wide range of developments and innovations in the global IT arena have led
                        to many new IT-enabled devices and services being produced. Moreover, there is need for IT
                        today, not just in urban areas but rural regions as well.</p>
                    <ul class="feature-icon mt-40">
                        <li>
                            <img src="assets/img/services/icon-1.png" alt="Icon">
                            <h6>Date Research Analysis</h6>
                        </li>
                        <li>
                            <img src="assets/img/services/icon-3.png" alt="Icon">
                            <h6>Date Research Analysis</h6>
                        </li>
                    </ul>
                    <a routerLink='/services' class="main-btn btn-filled mt-40">Our Services</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== FEATURE SECTION END ======-->
<!--====== SERVICES SECTION START ======-->
<section class="services-slider-secton">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-5 col-md-8 col-sm-7">
                <div class="section-title left-border">
                    <span class="title-tag">our services</span>
                    <h2>see what we do here with good passions</h2>
                </div>
            </div>
            <div class="col-lg-5 col-md-4 col-sm-5 d-none d-sm-block">
                <div class="service-link text-right">
                    <a routerLink='/services' class="main-btn btn-filled">learn more</a>
                </div>
            </div>
        </div>
    </div>
    <div class="serive-slider-wrap">
        <div class="container">
            <!-- Loop start -->
            <div class="services-slider row">
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-01.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Custom Software Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-02.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Outsoursing Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-03.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Software Product Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-01.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Custom Software Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-02.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Outsoursing Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-03.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Software Product Development</a>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== SERVICES SECTION END ======-->
<!--====== CALL TO ACTION START ======-->
<section class="call-to-action cta-style-two pt-120 pb-120">
    <div class="container">
        <div class="cta-inner">
            <div class="cat-img">
                <div class="cat-shape">
                    <img src="assets/img/cta/cta-img-2.png" alt="Image">
                </div>
            </div>
            <div class="cta-text">
                <div class="row align-items-center justify-content-end">
                    <div class="col-lg-6 col-md-7 col-sm-10">
                        <div class="section-title pl-30 left-border">
                            <span class="title-tag">need consultation</span>
                            <h2>We are here for your estimate price.</h2>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-2 col-md-2 col-sm-2">
                        <div class="link-wrap text-center">
                            <a routerLink='/contact-us' class="cat-link"><i class="fal fa-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== CALL TO ACTION END ======-->
<!--====== PORTFOLIO START ======-->
<section class="portfolio-slider-section pb-120">
    <div class="container-fluid">
        <!-- portfolio loop -->
        <div class="row portfolio-slider-loop portfolio-slider">
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/23.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/24.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/25.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/23.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/24.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/25.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== PORTFOLIO END ======-->
<!--====== TESTIMONIALS & clinet SECTION ======-->
<section class="testimonials-clinet-section pt-120 pb-120">
    <div class="container">
        <div class="section-title both-border text-center pb-80">
            <span class="title-tag">Testimonials</span>
            <h2>Client Feedbacks</h2>
        </div>
        <div class="testimonials-section pb-125">
            <div class="testimonials-slider-two row">
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/01.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/02.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/03.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/01.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/02.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-testimonial-two">
                        <p>Our team can assist you in transforming your business through latest tech capabilities to
                            stay ahead of the curve.</p>
                        <div class="testimonial-author">
                            <div class="author-img">
                                <img src="assets/img/testimonials/03.jpg" alt="Img">
                            </div>
                            <div class="author-info">
                                <h5>Rosalina d. william</h5>
                                <span>founder, cilixer co.</span>
                            </div>
                        </div>
                        <span class="quote-icon">
                            <img src="assets/img/testimonials/quote.png" alt="Icon">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="clinet-section">
        <div class="container-fluid custom-container-one">
            <div class="clinet-slider">
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/01.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/02.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/03.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/04.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/05.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/01.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/02.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/03.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/04.png" alt="Image">
                    </a>
                </div>
                <div class="clinet-item">
                    <a href="#">
                        <img src="assets/img/brand/05.png" alt="Image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== TESTIMONIALS & BRAND SECTION END ======-->
<!--====== LATEST POST START ======-->
<section class="latetest-post pt-120 pb-90">
    <div class="container">
        <!-- Section  Title -->
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-8 col-sm-7 col-6">
                <div class="section-title left-border">
                    <span class="title-tag">Blog</span>
                    <h2>news feeds</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-5 col-6">
                <div class="blog-btn text-right">
                    <a routerLink='/blog' class="main-btn btn-filled">learn more</a>
                </div>
            </div>
        </div>
        <!-- post Loop -->
        <div class="post-loop mt-70">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-12 col-sm-10 order-2 order-lg-1">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Software</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-10 col-sm-10 order-1 order-lg-2">
                    <div class="post-grid-box mb-30">
                        <ul class="post-cat">
                            <li><a routerLink='/blog-detail'>Business</a></li>
                        </ul>
                        <div class="post-desc">
                            <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the first
                                    screen has instructions.</a></h4>
                            <p>Too much data and forcing the brain to accommodate all these data frustrate the user
                                and leads them to abandon the task. The short term memory of our brain has
                                limitations.</p>
                        </div>
                        <ul class="post-meta">
                            <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                            <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== LATEST POST END ======-->
<app-footer2></app-footer2>
