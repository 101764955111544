<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/breadcrumb.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>Team details</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>Team details</li>
        </ul>
        <span class="btg-text">Beyond Agility Solutions</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== TEAM DETAILS START ======-->
<section class="team-details pt-150 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-5 col-md-9">
                <div class="member-img">
                    <img src="assets/img/details/team.jpg" alt="Image">
                </div>
            </div>
            <div class="col-lg-7 col-md-9">
                <div class="member-information">
                    <div class="member-info-top d-sm-flex align-items-center justify-content-between">
                        <div class="title">
                            <h2>Rosalina D. William</h2>
                            <span>Founder & UX Designer</span>
                        </div>
                        <div class="link">
                            <a routerLink='/contact-us' class="main-btn btn-filled">Get Appointment</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-9 col-md-11">
                            <ul class="contact-list">
                                <li>
                                    <i class="fas fa-phone"></i>
                                    <span class="title">Phone : </span>
                                    (123) 456-7890 8, +987 868 6578 648
                                </li>
                                <li>
                                    <i class="fas fa-envelope"></i>
                                    <span class="title">Email : </span>
                                    <a href="#">info@example.com</a>, <a href="#">job@webmail.com</a>
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    <span class="title">Address Info : </span>
                                    24 Fifth st., New York, US
                                </li>
                            </ul>
                            <ul class="social-list">
                                <li>
                                    <i class="fab fa-facebook-f"></i>
                                    <span class="title">Facebook : </span>
                                    <a href="#">www.facebook.com/rosalina</a>
                                </li>
                                <li>
                                    <i class="fab fa-twitter"></i>
                                    <span class="title">Twitter : </span>
                                    <a href="#">www.twitter.com/rosalina</a>
                                </li>
                                <li>
                                    <i class="fab fa-youtube"></i>
                                    <span class="title">Youtube : </span>
                                    <a href="#">www.youtube.com/rosalina</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== TEAM DETAILS END ======-->
<!--====== PORTFOLIO START ======-->
<section class="portfolio-slider-section with-section-bg pt-120 pb-120">
    <div class="container-fluid">
        <div class="section-title text-center both-border mb-80">
            <span class="title-tag">portfolio</span>
            <h2>case study</h2>
        </div>
        <!-- portfolio loop -->
        <div class="row portfolio-slider-loop portfolio-slider">
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/23.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/24.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/25.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/23.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/24.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/25.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== PORTFOLIO END ======-->
<app-footer3></app-footer3>
