<!--====== PRELOADER ======-->
<div id="preloader">
    <div>
        <div></div>
    </div>
</div>
<!--====== HEADER START ======-->
<header class="header-two sticky-header">
    <div class="header-top-area">
        <div class="container-fluid custom-container-two">
            <div class="row align-items-center">
                <div class="col-md-6 col-sm-7">
                    <ul class="contact-list">
                        <li><a href="#">contact@beyondagilitysolutions.com</a></li>
                        <li><a href="#">585-298-3695</a></li>
                    </ul>
                </div>
                <div class="col-md-6 col-sm-5">
                    <ul class="social-link">
                        <li><a href="#">fb</a></li>
                        <li><a href="#">tw</a></li>
                        <li><a href="#">be</a></li>
                        <li><a href="#">yu</a></li>
                        <li><a href="#">li</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="mainmenu-area">
        <div class="container-fluid custom-container-two">
            <div class="d-flex align-items-center justify-content-between">
                <nav class="main-menu">
                    <div class="logo">
                        <a routerLink=''><img src="assets/img/logo.png" alt="Onitit"></a>
                    </div>
                    <div class="menu-items">
                        <ul>
                            <li class="active">
                                <a href="javascript:void(0)">Home</a>
                                <ul class="submenu">
                                    <li><a routerLink='' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>
                                    <li><a routerLink='/homepage-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>
                                    <li><a routerLink='/homepage-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home There</a></li>
                                </ul>
                            </li>
<!--                            <li>-->
<!--                                <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>-->
<!--                            </li>-->
                            <li>
                                <a href="javascript:void(0)">Services</a>
                                <ul class="submenu">
                                    <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                                    <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                                </ul>
                            </li>
<!--                            <li>-->
<!--                                <a href="javascript:void(0)">Pages</a>-->
<!--                                <ul class="submenu">-->
<!--                                    <li>-->
<!--                                        <a routerLink='/why-choose-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Why Choose Us</a>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="javascript:void(0)">Team</a>-->
<!--                                        <ul class="submenu">-->
<!--                                            <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>-->
<!--                                            <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team Details</a></li>-->
<!--                                        </ul>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="javascript:void(0)">Portfolio</a>-->
<!--                                        <ul class="submenu">-->
<!--                                            <li><a routerLink='/portfolio-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Grid</a></li>-->
<!--                                            <li><a routerLink='/portfolio-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Masonary</a></li>-->
<!--                                            <li><a routerLink='/portfolio-slider' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Slider</a></li>-->
<!--                                            <li><a routerLink='/portfolio-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Details</a></li>-->
<!--                                        </ul>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="javascript:void(0)">News</a>-->
<!--                                        <ul class="submenu">-->
<!--                                            <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Standard</a> </li>-->
<!--                                            <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Grid</a> </li>-->
<!--                                            <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Details</a></li>-->
<!--                                        </ul>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a routerLink='/faqs' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="javascript:void(0)">News</a>-->
<!--                                <ul class="submenu">-->
<!--                                    <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Standard</a> </li>-->
<!--                                    <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Grid</a> </li>-->
<!--                                    <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Details</a></li>-->
<!--                                </ul>-->
<!--                            </li>-->
                            <li>
                                <a routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div class="main-right">
                    <div class="quote-icon">
                        <img src="assets/img/clock-icon.png" class="icon" alt="Clock">
<!--                        <span>-->
<!--                            Get A Quote-->
<!--                            <span>988. 876 76 76 8</span>-->
<!--                        </span>-->
                    </div>
                    <a href="#" class="offcanvas-trigger">
                        <i class="far fa-ellipsis-v"></i>
                    </a>
                    <!-- OFF CANVAS WRAP START -->
                    <div class="off-canvas-wrap">
                        <div class="overly"></div>
                        <div class="off-canvas-widget">
                            <a href="#" class="off-canvas-close"><i class="fal fa-times"></i></a>
                            <div class="widget recent-post">
                                <h4 class="widget-title">Recent Posts</h4>
                                <ul>
                                    <li>
                                        <div class="post-img"
                                            style="background-image: url(assets/img/laest-post/01.jpg);">
                                        </div>
                                        <div class="post-content">
                                            <h6>
                                                <a routerLink='/blog-detail'>Signs moved moveth itself on years whose
                                                    without.</a>
                                            </h6>
                                            <span class="time"><i class="far fa-clock"></i>4 May.2020</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="post-img"
                                            style="background-image: url(assets/img/laest-post/02.jpg);">
                                        </div>
                                        <div class="post-content">
                                            <h6>
                                                <a routerLink='/blog-detail'>Under brought airte them saw stars
                                                    created seas.</a>
                                            </h6>
                                            <span class="time"><i class="far fa-clock"></i>4 May.2020</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="post-img"
                                            style="background-image: url(assets/img/laest-post/03.jpg);">
                                        </div>
                                        <div class="post-content">
                                            <h6>
                                                <a routerLink='/blog-detail'>Our form there seas set forth fill
                                                    bearing.</a>
                                            </h6>
                                            <span class="time"><i class="far fa-clock"></i>4 May.2020</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="widget contact-widget">
                                <h4 class="widget-title">Contact Us</h4>
                                <ul>
                                    <li>
                                        <i class="far fa-map-marker-alt"></i>
                                        305 Royal Track Suite 019, USA
                                    </li>
                                    <li>
                                        <i class="far fa-phone"></i>
                                        <a href="#">+10910-910920</a>
                                    </li>
                                    <li>
                                        <i class="far fa-envelope-open"></i>
                                        <a href="#">contact@beyondagilitysolutions.com</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="widget social-widget">
                                <h4 class="widget-title">Follow Us</h4>
                                <ul>
                                    <li>
                                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a href="#"><i class="fab fa-twitter"></i></a>
                                        <a href="#"><i class="fab fa-instagram"></i></a>
                                        <a href="#"><i class="fab fa-behance"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- OFF CANVAS WRAP END -->
                </div>
            </div>
        </div>
    </div>
    <!-- Mobile Menu -->
    <div class="row">
        <div class="col-12">
            <div class="mobile-menu"></div>
        </div>
    </div>
</header>
<!--====== HEADER END ======-->

