<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/breadcrumb.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>why choose us</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>why choose us</li>
        </ul>
        <span class="btg-text">Beyond Agility Solutions</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== ABOUT SECTION START ======-->
<section class="about-section">
    <div class="container">
        <div class="with-man-shape">
            <div class="row justify-content-center justify-content-lg-end">
                <div class="col-lg-6 col-md-10">
                    <div class="about-text">
                        <div class="section-title mb-40 left-border">
                            <span class="title-tag">ou impressions</span>
                            <h2>Beyond Agility Solutions help businesses elevate their value.</h2>
                        </div>
                        <p>
                            Over the years, a wide range of developments and innovations in the global IT arena have
                            led
                            to many new IT-enabled devices and services being produced. Moreover, there is need for
                            IT
                            today, not just in urban areas but rural regions as well.
                        </p>
                        <div class="about-features mt-50">
                            <div class="sngle-features">
                                <div class="chart" data-percent="75">
                                    <span class="icon"><i class="fas fa-award"></i></span>
                                </div>
                                <div class="counter-box">
                                    <span class="timer">32</span><span>+</span>
                                </div>
                                <div class="desc">
                                    <h4>Years of Experience</h4>
                                    <p>As a result, most of us need to know how to use computers. Our knowledge of
                                        computers.</p>
                                </div>
                            </div>
                            <div class="sngle-features">
                                <div class="chart" data-percent="85">
                                    <span class="icon"><i class="fas fa-globe"></i></span>
                                </div>
                                <div class="counter-box">
                                    <span class="timer">99</span><span>+</span>
                                </div>
                                <div class="desc">
                                    <h4>Project Done together</h4>
                                    <p>As a result, most of us need to know how to use computers. Our knowledge of
                                        computers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-man-img">
                <div class="shape">
                    <img src="assets/img/about/why_choose_us.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== ABOUT SECTION END ======-->
<!--====== CONTERUP PART START ======-->
<section class="counter-section">
    <div class="container">
        <div class="counter-inner">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="counter-box">
                        <h1><span class="counter">299</span><span>+</span></h1>
                        <p class="title">Project Done</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="counter-box">
                        <h1><span class="counter">177</span><span>+</span></h1>
                        <p class="title">Active Client</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="counter-box">
                        <h1><span class="counter">5</span><span>K+</span></h1>
                        <p class="title">Drink Coffee</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="counter-box">
                        <h1><span class="counter">69</span><span>K+</span></h1>
                        <p class="title">Award winning</p>
                    </div>
                </div>
            </div>
            <span class="big-text">
                Beyond Agility Solutions
            </span>
        </div>
    </div>
</section>
<!--====== CONTERUP PART END ======-->
<!--======  FEATURED SERVICES SECTION START ======-->
<section class="services-secton featured-service pt-100 pb-60">
    <div class="container">
        <div class="section-title both-border text-center mb-80">
            <span class="title-tag">services</span>
            <h2>what we do</h2>
        </div>
        <div class="services-loop">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-10">
                    <div class="single-service text-center white-bg mb-60">
                        <div class="icon">
                            <img src="assets/img/services/icon-1.png" alt="Icon">
                        </div>
                        <h4>Date Research Analysis</h4>
                        <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore.</p>
                        <a routerLink='/service-detail' class="service-link">Read More</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-10">
                    <div class="single-service text-center secondary-bg doted mb-60">
                        <div class="icon">
                            <img src="assets/img/services/icon-2.png" alt="Icon">
                        </div>
                        <h4>Date Research Analysis</h4>
                        <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore.</p>
                        <a routerLink='/service-detail' class="service-link">Read More</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-10">
                    <div class="single-service text-center primary-bg mb-60">
                        <div class="icon">
                            <img src="assets/img/services/icon-3.png" alt="Icon">
                        </div>
                        <h4>Maintainance & Data sent</h4>
                        <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore.</p>
                        <a routerLink='/service-detail' class="service-link">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== FEATURED SERVICES SECTION END ======-->
<!--====== PORTFOLIO START ======-->
<section class="portfolio-slider-section with-bg pt-120 pb-120">
    <div class="container-fluid">
        <div class="section-title text-center both-border mb-80">
            <span class="title-tag">portfolio</span>
            <h2>case study</h2>
        </div>
        <!-- portfolio loop -->
        <div class="row portfolio-slider-loop portfolio-slider">
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/23.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/24.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/25.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/23.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/24.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="portfolio-box">
                    <div class="portfolio-img-wrap">
                        <div class="portfolio-img" style="background-image: url(assets/img/portfolio/25.jpg);">
                        </div>
                    </div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">ui/ux design, mobile app</span>
                        <h2><a routerLink='/portfolio-detail'>Miranda grocery app design</a></h2>
                        <a routerLink='/portfolio-detail' class="portfolio-link">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== PORTFOLIO END ======-->
<!--====== LATEST POST START ======-->
<section class="latetest-post pt-120 pb-90">
    <div class="container">
        <!-- Section  Title -->
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-8 col-sm-7 col-6">
                <div class="section-title left-border">
                    <span class="title-tag">Blog</span>
                    <h2>news feeds</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-5 col-6">
                <div class="blog-btn text-right">
                    <a routerLink='/blog' class="main-btn btn-filled">learn more</a>
                </div>
            </div>
        </div>
        <!-- post Loop -->
        <div class="post-loop mt-70">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-12 col-sm-10 order-2 order-lg-1">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Software</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-10 col-sm-10 order-1 order-lg-2">
                    <div class="post-grid-box mb-30">
                        <ul class="post-cat">
                            <li><a routerLink='/blog-detail'>Business</a></li>
                        </ul>
                        <div class="post-desc">
                            <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the first
                                    screen has instructions.</a></h4>
                            <p>Too much data and forcing the brain to accommodate all these data frustrate the user
                                and leads them to abandon the task. The short term memory of our brain has
                                limitations.</p>
                        </div>
                        <ul class="post-meta">
                            <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                            <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== LATEST POST END ======-->
<app-footer3></app-footer3>
