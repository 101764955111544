<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/breadcrumb.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>faq</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>faq</li>
        </ul>
        <span class="btg-text">Beyond Agility Solutions</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->

<section class="faq-wrapper pt-120 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="faq-accordion faq-loop accordion" id="faqAccordion">
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqOne">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqOne" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" class="active-accordion" data-toggle="collapse"
                                data-target="#faqtwo">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqtwo" class="collapse show" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqThree">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqThree" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqFour">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqFour" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqFive">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqFive" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqSix">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqSix" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqSeven">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqSeven" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqEight">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqEight" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqNine">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqNine" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <button type="button" data-toggle="collapse" data-target="#faqTen">
                                Under normal circumstances a fee for IT consulting ?
                                <span class="icon"><span>+</span></span>
                            </button>
                        </div>
                        <div id="faqTen" class="collapse" data-parent="#faqAccordion">
                            <div class="card-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-10">
                <!-- Page Sidebar -->
                <div class="sidebar">
                    <!-- Contact Widget -->
                    <div class="widget contact-widget mb-40">
                        <h5 class="widget-title">Contact Us</h5>
                        <form action="#">
                            <div class="input-group">
                                <span class="icon"><i class="fas fa-user"></i></span>
                                <input type="text" placeholder="Enter Your Name">
                            </div>
                            <div class="input-group">
                                <span class="icon"><i class="fas fa-envelope"></i></span>
                                <input type="email" placeholder="Enter email">
                            </div>
                            <div class="input-group textarea">
                                <span class="icon"><i class="fas fa-edit"></i></span>
                                <textarea placeholder="Enter message"></textarea>
                            </div>
                            <div class="text-center mt-20"><button type="submit" class="main-btn btn-filled">Get A
                                    QUote</button></div>
                        </form>
                    </div>
                    <!-- Bannner Widget -->
                    <div class="widget banner-ad-widget">
                        <img src="assets/img/banner-widget-2.jpg" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer3></app-footer3>
