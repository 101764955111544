<app-header></app-header>
<!--====== BANNER SECTION START ======-->
<section class="banner-section" style="background-image: url(assets/img/banner/01.jpg);">
    <div class="slider-active" id="bannerSliderOne">
        <div class="single-banner">
            <div class="container container-custom">
                <div class="row extra-left">
                    <div class="col-lg-8">
                        <div class="banner-text">
                            <h1 data-animation="fadeInUp" data-delay=".7s" style="animation-delay: 0.7s;">We Create
                                Perfect Brand Goods Value</h1>
                            <p data-animation="fadeInUp" data-delay=".9s">Bring your ideas to life with us</p>
                            <div class="btn-wrap" data-animation="fadeIn" data-delay="1.5s">
                                <a routerLink='contact-us' class="main-btn btn-filled">Get Started Now</a>
                                <a routerLink='about' class="main-btn btn-borderd">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-banner">
            <div class="container container-custom">
                <div class="row extra-left">
                    <div class="col-lg-8">
                        <div class="banner-text">
                            <h1 data-animation="fadeInUp" data-delay=".7s" style="animation-delay: 0.7s;">We Create
                                Perfect Brand Goods Value</h1>
                            <p data-animation="fadeInUp" data-delay=".9s">Bring your ideas to life with us</p>
                            <div class="btn-wrap" data-animation="fadeIn" data-delay="1.5s">
                                <a routerLink='contact-us' class="main-btn btn-filled">Get Started Now</a>
                                <a routerLink='about' class="main-btn btn-borderd">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-banner">
            <div class="container container-custom">
                <div class="row extra-left">
                    <div class="col-lg-8">
                        <div class="banner-text">
                            <h1 data-animation="fadeInUp" data-delay=".7s" style="animation-delay: 0.7s;">We Create
                                Perfect Brand Goods Value</h1>
                            <p data-animation="fadeInUp" data-delay=".9s">Bring your ideas to life with us</p>
                            <div class="btn-wrap" data-animation="fadeIn" data-delay="1.5s">
                                <a routerLink='contact-us' class="main-btn btn-filled">Get Started Now</a>
                                <a routerLink='about' class="main-btn btn-borderd">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape-one"></div>
    <div class="banner-shape-two"></div>
</section>
<!--====== BANNER SECTION END ======-->
<!--====== ABOUT SECTION START ======-->
<section class="about-section pt-120 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="about-tile-gallery">
                    <img src="assets/img/tile-gallery/01.jpg" alt="Image" class="image-one">
                    <img src="assets/img/tile-gallery/02.jpg" alt="Image" class="image-two">
                    <img src="assets/img/tile-gallery/03.jpg" alt="Image" class="image-three">
                    <img src="assets/img/tile-gallery/icon.png" alt="icon" class="icon">
                    <img src="assets/img/tile-gallery/icon-2.png" alt="icon" class="zero-icon">
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class="about-text pl-25">
                    <div class="section-title mb-40 left-border">
                        <span class="title-tag">About us</span>
                        <h2>Beyond Agility Solutions help businesses elevate their value.</h2>
                    </div>
                    <p>
                        Over the years, a wide range of developments and innovations in the global IT arena have led
                        to many new IT-enabled devices and services being produced. Moreover, there is need for IT
                        today, not just in urban areas but rural regions as well.
                    </p>
                    <div class="about-features mt-50">
                        <div class="sngle-features">
                            <div class="chart" data-percent="75">
                                <span class="icon"><i class="fas fa-award"></i></span>
                            </div>
                            <div class="counter-box">
                                <span class="timer">32</span><span>+</span>
                            </div>
                            <div class="desc">
                                <h4>Years of Experience</h4>
                                <p>As a result, most of us need to know how to use computers. Our knowledge of
                                    computers.</p>
                            </div>
                        </div>
                        <div class="sngle-features">
                            <div class="chart" data-percent="85">
                                <span class="icon"><i class="fas fa-globe"></i></span>
                            </div>
                            <div class="counter-box">
                                <span class="timer">99</span><span>+</span>
                            </div>
                            <div class="desc">
                                <h4>Project Done together</h4>
                                <p>As a result, most of us need to know how to use computers. Our knowledge of
                                    computers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== ABOUT SECTION END ======-->
<!--====== SERVICES SECTION START ======-->
<section class="services-secton pt-120 pb-200">
    <div class="container">
        <div class="section-title mb-80 text-center both-border">
            <span class="title-tag">Services</span>
            <h2>What We Do</h2>
        </div>
        <div class="services-loop">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-10">
                    <div class="single-service text-center white-bg">
                        <div class="icon">
                            <img src="assets/img/services/icon-1.png" alt="Icon">
                        </div>
                        <h4>Date Research Analysis</h4>
                        <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore.</p>
                        <a routerLink='service-detail' class="service-link">Read More</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-10">
                    <div class="single-service text-center secondary-bg doted mt-60">
                        <div class="icon">
                            <img src="assets/img/services/icon-2.png" alt="Icon">
                        </div>
                        <h4>Date Research Analysis</h4>
                        <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore.</p>
                        <a routerLink='service-detail' class="service-link">Read More</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-10">
                    <div class="single-service text-center primary-bg mt-120">
                        <div class="icon">
                            <img src="assets/img/services/icon-3.png" alt="Icon">
                        </div>
                        <h4>Maintainance & Data sent</h4>
                        <p>Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor
                            incididunt ut labore.</p>
                        <a routerLink='service-detail' class="service-link">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== SERVICES SECTION END ======-->
<!--====== CALL TO ACTION START ======-->
<section class="call-to-action">
    <div class="container">
        <div class="cta-inner">
            <div class="cat-img">
                <img src="assets/img/cta/cta-img.png" alt="Image">
            </div>
            <div class="cta-text">
                <div class="row align-items-end">
                    <div class="col-lg-5 offset-lg-5 col-md-7 offset-md-5">
                        <div class="section-title pl-30 left-border">
                            <span class="title-tag">Get A Quote</span>
                            <h2>We are here for your estimate price.</h2>
                        </div>
                    </div>
                </div>
                <a routerLink='contact-us' class="cat-link"><i class="fal fa-long-arrow-right"></i></a>
            </div>
        </div>
    </div>
</section>
<!--====== CALL TO ACTION END ======-->
<!--====== FEATURE SECTION START ======-->
<section class="features-boxes pt-120 pb-110">
    <div class="container">
        <!-- Section Title -->
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-8 col-sm-7">
                <div class="section-title left-border">
                    <span class="title-tag">Why Us</span>
                    <h2>Why Choose Us</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
                <div class="text-right">
                    <a routerLink='why-choose-us' class="main-btn btn-filled">LEARN MORE</a>
                </div>
            </div>
        </div>
        <!-- Feature boxes loop -->
        <div class="features-boxes-loop mt-100">
            <div class="row justify-content-center justify-content-md-between">
                <div class="col-lg-4 col-md-5 col-sm-10">
                    <div class="feature-box">
                        <h4><i class="fal fa-browser"></i> unlimited layout</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                    <div class="feature-box">
                        <h4><i class="fal fa-cog"></i> Easy to customize</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                    <div class="feature-box">
                        <h4><i class="fal fa-headphones-alt"></i> 24/7 online support</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                </div>
                <div class="gap-for-img"></div>
                <div class="col-lg-4 col-md-5 col-sm-10">
                    <div class="feature-box">
                        <h4><i class="fal fa-desktop"></i> Retina ready work</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                    <div class="feature-box">
                        <h4><i class="fal fa-globe"></i> Global language</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                    <div class="feature-box">
                        <h4><i class="fal fa-lock"></i> High quality security</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- feature-img -->
        <div class="feature-img">
            <img src="assets/img/feature-img.png" alt="Image">
        </div>
    </div>
</section>
<!--====== FEATURE SECTION END ======-->
<!--====== VIDEO SECTION START ======-->
<section class="video-section">
    <div class="container">
        <div class="row justify-content-center justify-content-lg-end">
            <div class="col-lg-5 col-md-8">
                <div class="video-text pl-30">
                    <div class="section-title left-border mb-40">
                        <span class="title-tag">intro video</span>
                        <h2>Let’s make an office tour from here.</h2>
                    </div>
                    <p>Over the years, a wide range of developments & innovations in the global IT arena have led to
                        many new IT-enabled devices and services being produced.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Video-wrap -->
    <div class="video-wrap">
        <div class="video-bg" style="background-image: url(assets/img/video-bg.jpg);">
            <div class="video-link">
                <a href="#" class="popup-video">play</a>
            </div>
        </div>
    </div>
</section>
<!--====== VIDEO SECTION END ======-->
<!--====== TEAM SECTION START ======-->
<section class="team-section has-slider pt-120 pb-120">
    <div class="container-fluid">
        <div class="row team-loop team-slider-one">
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/01.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>Rosa D.William</h3>
                        <span>Founder & CEO</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/02.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>hilixer b. browni</h3>
                        <span>co-founder</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/03.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>pokoloko k. kilix</h3>
                        <span>consultant</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/04.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>pokoloko k. kilix</h3>
                        <span>consultant</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/05.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>pokoloko k. kilix</h3>
                        <span>consultant</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/01.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>Rosalina D. William</h3>
                        <span>Founder & CEO</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/02.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>hilixer b. browni</h3>
                        <span>co-founder</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/03.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>pokoloko k. kilix</h3>
                        <span>consultant</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/04.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>pokoloko k. kilix</h3>
                        <span>consultant</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== TEAM SECTION END ======-->
<!--====== SKILLS SECTION START ======-->
<section class="skills-section pt-120 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-8 col-sm-10">
                <div class="skill-bars">
                    <div class="section-title mb-60 left-border">
                        <span class="title-tag">skillset</span>
                        <h2> Check Skillset & Manupulation </h2>
                    </div>
                    <div class="skill-progress mb-45">
                        <div class="title d-flex justify-content-between">
                            <span>Consulting & Marketing</span>
                            <span>72%</span>
                        </div>
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="72">
                            </div>
                        </div>
                    </div>
                    <div class="skill-progress mb-45">
                        <div class="title d-flex justify-content-between">
                            <span>it solution & travelshooting</span>
                            <span>81%</span>
                        </div>
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="81">
                            </div>
                        </div>
                    </div>
                    <div class="skill-progress">
                        <div class="title d-flex justify-content-between">
                            <span>uix solution</span>
                            <span>72%</span>
                        </div>
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="45">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-8 col-sm-10">
                <div class="skill-img text-right">
                    <img src="assets/img/skill-img.jpg" alt="Image">
                </div>
            </div>
            <a routerLink='contact-us' class="main-btn btn-filled hire-btn">Hire Us Now</a>
        </div>
    </div>
</section>
<!--====== SKILLS SECTION END ======-->
<!--====== PORTFOLIO START ======-->
<section class="portfolio-section pt-120 pb-90">
    <div class="container">
        <div class="section-title text-center both-border mb-80">
            <span class="title-tag">portfolio</span>
            <h2>case study</h2>
        </div>
        <!-- portfolio loop -->
        <div class="row portfolio-masonary-loop">
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-box height-extra">
                    <div class="portfolio-img" style="background-image: url(assets/img/portfolio/01.jpg);"></div>
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-box height-extra" style="background-image: url(assets/img/portfolio/02.jpg);">
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-box height-extra" style="background-image: url(assets/img/portfolio/03.jpg);">
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-box height-extra" style="background-image: url(assets/img/portfolio/04.jpg);">
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-sm-12">
                <div class="portfolio-box height-extra" style="background-image: url(assets/img/portfolio/05.jpg);">
                    <div class="portfolio-desc">
                        <span class="portfolio-cat">uix design</span>
                        <h4><a routerLink='portfolio-detail'>mixer website solution</a></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== PORTFOLIO END ======-->
<!--====== TESTIMONIAL START ======-->
<section class="testimonial-section pt-120 pb-120">
    <div class="container">
        <!-- testimonial Content Slider-->
        <div class="testimonial-slider mb-45">
            <div>
                <div class="single-testimonial">
                    <div class="author-img">
                        <img src="assets/img/test-author.png" alt="Author">
                    </div>
                    <div class="desc">
                        <div class="rateing mb-20">
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fal fa-star"></i></a>
                        </div>
                        <h2>“ Online proofing smart review allows pages within multi page documents to be
                            treated as. Aproove was built to be
                            content is real. ”</h2>
                    </div>
                </div>
            </div>
            <div>
                <div class="single-testimonial">
                    <div class="author-img">
                        <img src="assets/img/test-author.png" alt="Author">
                    </div>
                    <div class="desc">
                        <div class="rateing mb-20">
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fal fa-star"></i></a>
                        </div>
                        <h2>“ Online proofing smart review allows pages within multi page documents to be
                            treated as. Aproove was built to be
                            content is real. ”</h2>
                    </div>
                </div>
            </div>
            <div>
                <div class="single-testimonial">
                    <div class="author-img">
                        <img src="assets/img/test-author.png" alt="Author">
                    </div>
                    <div class="desc">
                        <div class="rateing mb-20">
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fal fa-star"></i></a>
                        </div>
                        <h2>“ Online proofing smart review allows pages within multi page documents to be
                            treated as. Aproove was built to be
                            content is real. ”</h2>
                    </div>
                </div>
            </div>
            <div>
                <div class="single-testimonial">
                    <div class="author-img">
                        <img src="assets/img/test-author.png" alt="Author">
                    </div>
                    <div class="desc">
                        <div class="rateing mb-20">
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fal fa-star"></i></a>
                        </div>
                        <h2>“ Online proofing smart review allows pages within multi page documents to be
                            treated as. Aproove was built to be
                            content is real. ”</h2>
                    </div>
                </div>
            </div>
            <div>
                <div class="single-testimonial">
                    <div class="author-img">
                        <img src="assets/img/test-author.png" alt="Author">
                    </div>
                    <div class="desc">
                        <div class="rateing mb-20">
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fal fa-star"></i></a>
                        </div>
                        <h2>“ Online proofing smart review allows pages within multi page documents to be
                            treated as. Aproove was built to be
                            content is real. ”</h2>
                    </div>
                </div>
            </div>
            <div>
                <div class="single-testimonial">
                    <div class="author-img">
                        <img src="assets/img/test-author.png" alt="Author">
                    </div>
                    <div class="desc">
                        <div class="rateing mb-20">
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"><i class="fal fa-star"></i></a>
                        </div>
                        <h2>“ Online proofing smart review allows pages within multi page documents to be
                            treated as. Aproove was built to be
                            content is real. ”</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Author Info Slider -->
        <div class="row no-gutters justify-content-center">
            <div class="col-lg-9">
                <div class="testimonial-author-slider">
                    <div class="single-slider">
                        <h4>Rosalina D. William</h4>
                        <span>Founder, Kilixer Co.</span>
                    </div>
                    <div class="single-slider">
                        <h4>Rosalina D. William</h4>
                        <span>Founder, Kilixer Co.</span>
                    </div>
                    <div class="single-slider">
                        <h4>Rosalina D. William</h4>
                        <span>Founder, Kilixer Co.</span>
                    </div>
                    <div class="single-slider">
                        <h4>Rosalina D. William</h4>
                        <span>Founder, Kilixer Co.</span>
                    </div>
                    <div class="single-slider">
                        <h4>Rosalina D. William</h4>
                        <span>Founder, Kilixer Co.</span>
                    </div>
                    <div class="single-slider">
                        <h4>Rosalina D. William</h4>
                        <span>Founder, Kilixer Co.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== TESTIMONIAL END ======-->
<!--====== LATEST POST START ======-->
<section class="latetest-post pt-120 pb-90">
    <div class="container">
        <div class="section-title text-center both-border mb-80">
            <span class="title-tag">Blog</span>
            <h2>news feeds</h2>
        </div>
        <!-- Blog Loop -->
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="latest-post-box mb-30 text-center">
                    <ul class="post-meta">
                        <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                        <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                    </ul>
                    <div class="post-img mb" style="background-image: url(assets/img/laest-post/01.jpg);"></div>
                    <div class="post-desc mt-35">
                        <h3><a routerLink='/blog-detail'>We’ll then ask you to tell us your current level</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut
                            labore et dolore.</p>
                        <a routerLink='/blog-detail' class="post-link"> Read More </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="latest-post-box mb-30 text-center">
                    <ul class="post-meta">
                        <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                        <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                    </ul>
                    <div class="post-img mb" style="background-image: url(assets/img/laest-post/02.jpg);"></div>
                    <div class="post-desc mt-35">
                        <h3><a routerLink='/blog-detail'>We’ll then ask you to tell us your current level</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut
                            labore et dolore.</p>
                        <a routerLink='/blog-detail' class="post-link"> Read More </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-10">
                <div class="latest-post-box mb-30 text-center">
                    <ul class="post-meta">
                        <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                        <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                    </ul>
                    <div class="post-img mb" style="background-image: url(assets/img/laest-post/03.jpg);"></div>
                    <div class="post-desc mt-35">
                        <h3><a routerLink='/blog-detail'>We’ll then ask you to tell us your current level</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt ut
                            labore et dolore.</p>
                        <a routerLink='/blog-detail' class="post-link"> Read More </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== LATEST POST END ======-->
<app-footer></app-footer>
