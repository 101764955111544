<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/breadcrumb.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>news feeds</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>Blog</li>
        </ul>
        <span class="btg-text">Beyond Agility Solutions</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== BLOG SECTION START ======-->
<section class="blog-section pt-120 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <!-- Blog Loop Start -->
            <div class="col-lg-8 col-md-8 col-sm-10">
                <div class="blog-loop">
                    <div class="row justify-content-center">
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Business</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the
                                            first
                                            screen has instructions.</a></h4>
                                    <p>Too much data and forcing the brain to accommodate all these data frustrate
                                        the user
                                        and leads them to abandon the task. The short term memory of our brain has.
                                    </p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a href="#"><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a href="#"><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Blog Sidebar -->
            <div class="col-lg-4 col-md-8 col-sm-10">
                <app-blogsidebar></app-blogsidebar>
            </div>
        </div>
    </div>
</section>
<!--====== BLOG SECTION END ======-->
<app-footer3></app-footer3>
