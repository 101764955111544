<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/86.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>contact us</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>contact us</li>
        </ul>
        <span class="btg-text">Tek Assets</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== CONTACT SECTION START ======-->
<section class="contact-section with-map-bg pt-120 pb-120">
    <div class="container">
        <div class="contact-form-wrapper">
            <div class="row no-gutters align-items-center align-items-lg-start justify-content-center">
                <div class="col-lg-5 col-md-5">
                    <div class="contact-info-list">
                        <div class="info-box">
                            <div class="icon">
                                <i class="pe-7s-home"></i>
                            </div>
                            <div class="content">
                                <h4>Office Address</h4>
                                <p> 44355 premier plaza, suite 120
                                  <br>Ashburn, VA 20147 </p>
                            </div>
                        </div>
                        <div class="info-box">
                            <div class="icon">
                                <i class="pe-7s-call"></i>
                            </div>
                            <div class="content">
                                <h4>Phone Number</h4>
                                <p>(240)642-1085‬<br></p>
                            </div>
                        </div>
                        <div class="info-box">
                            <div class="icon">
                                <i class="pe-7s-comment"></i>
                            </div>
                            <div class="content">
                                <h4>Email address</h4>
                                <p> hr@tek-assets.com <br></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-7">
                    <div class="contact-form">
                        <form action="#">
                            <div class="row padding-custom">
                                <div class="col-12 col-lg-6">
                                    <div class="input-group mb-10">
                                        <div class="icon">
                                            <i class="fal fa-user"></i>
                                        </div>
                                        <input type="text" placeholder="Your name">
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="input-group mb-10">
                                        <div class="icon">
                                            <i class="fal fa-envelope"></i>
                                        </div>
                                        <input type="text" placeholder="Your email">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group mb-10">
                                        <div class="icon">
                                            <i class="fal fa-envelope"></i>
                                        </div>
                                        <input type="text" placeholder="Your email">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group textarea mb-10">
                                        <div class="icon">
                                            <i class="fal fa-edit"></i>
                                        </div>
                                        <textarea placeholder="Enter message"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <button type="submit" class="main-btn btn-filled">Get A QUote</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="contact-maps">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d180508.00515044518!2d90.61153473006058!3d23.544910075716373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1592212793858!5m2!1sen!2sbd">
            </iframe>
        </div> -->
    </div>
</section>
<!--====== CONTACT SECTION END ======-->
<app-footer3></app-footer3>
