import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicedetail',
  templateUrl: './servicedetail.component.html',
  styleUrls: ['./servicedetail.component.css']
})
export class ServicedetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
