<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/breadcrumb.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>Our Team</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>our team</li>
        </ul>
        <span class="btg-text">Beyond Agility Solutions</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== TEAM SECTION START ======-->
<section class="team-section pt-120 pb-90">
    <div class="container">
        <div class="row justify-content-center team-loop">
            <div class="col-lg-4 col-sm-6">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/01.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>Rosa D.William</h3>
                        <span>Founder & CEO</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/02.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>hilixer b. browni</h3>
                        <span>co-founder</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/03.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>pokoloko k. kilix</h3>
                        <span>consultant</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/04.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>pokoloko k. kilix</h3>
                        <span>consultant</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="member-box">
                    <div class="member-img">
                        <img src="assets/img/team/05.jpg" alt="Team-Image">
                    </div>
                    <div class="member-info">
                        <h3>pokoloko k. kilix</h3>
                        <span>consultant</span>
                    </div>
                    <a href="#" class="socail-trigger">+</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== TEAM SECTION END ======-->
<!--====== SERVICES SECTION START ======-->
<section class="services-slider-secton">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-5 col-md-8 col-sm-7">
                <div class="section-title left-border">
                    <span class="title-tag">our services</span>
                    <h2>see what we do here with good passions</h2>
                </div>
            </div>
            <div class="col-lg-5 col-md-4 col-sm-5 d-none d-sm-block">
                <div class="service-link text-right">
                    <a routerLink='/services' class="main-btn btn-filled">learn more</a>
                </div>
            </div>
        </div>
    </div>
    <div class="serive-slider-wrap">
        <div class="container">
            <!-- Loop start -->
            <div class="services-slider row">
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-01.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Custom Software Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-02.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Outsoursing Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-03.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Software Product Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-01.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Custom Software Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-02.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Outsoursing Development</a>
                        </h6>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="single-slider" style="background-image: url(assets/img/services/img-03.jpg);">
                        <h6>
                            <a routerLink='/service-detail'>Software Product Development</a>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== SERVICES SECTION END ======-->
<!--====== SKILLS SECTION START ======-->
<section class="skills-section bg-transparent pt-120 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                <div class="skill-bars">
                    <div class="section-title mb-60 left-border">
                        <span class="title-tag">skillset</span>
                        <h2> Check Skillset & Manupulation </h2>
                    </div>
                    <div class="skill-progress mb-45">
                        <div class="title d-flex justify-content-between">
                            <span>Consulting & Marketing</span>
                            <span>72%</span>
                        </div>
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="72">
                            </div>
                        </div>
                    </div>
                    <div class="skill-progress mb-45">
                        <div class="title d-flex justify-content-between">
                            <span>it solution & travelshooting</span>
                            <span>81%</span>
                        </div>
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="81">
                            </div>
                        </div>
                    </div>
                    <div class="skill-progress">
                        <div class="title d-flex justify-content-between">
                            <span>uix solution</span>
                            <span>72%</span>
                        </div>
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="45">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-md-8 col-sm-10">
                <div class="skill-img text-right">
                    <img src="assets/img/skill-img-2.jpg" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== SKILLS SECTION END ======-->
<app-footer3></app-footer3>
