<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/breadcrumb.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>About Us</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>About Us</li>
        </ul>
        <span class="btg-text">Beyond Agility Solutions</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->
<!--====== ABOUT SECTION START ======-->
<section class="about-section about-style-three pt-120 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="about-tile-gallery-two">
                    <img src="assets/img/tile-gallery/04.jpg" alt="Image" class="image-one">
                    <img src="assets/img/tile-gallery/05.jpg" alt="Image" class="image-two">
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class="about-text pl-30">
                    <div class="section-title left-border mb-40">
                        <span class="title-tag">About us</span>
                        <h2>Tek Assets help businesses elevate their value.</h2>
                    </div>
                    <p>
                        Over the years, a wide range of developments and innovations in the global IT
                        arena have led to many new IT-enabled devices and services being produced. Moreover, there
                        is need for IT today, not just in urban areas but rural regions as well.
                    </p>
                    <div class="about-extra">
                        Over the years, a wide range of develop ments and innovations in the global IT arena have
                        led to many new IT-enabled devices and services being produced.
                        <div class="experience-tag">
                            <img src="assets/img/experience-tag.png" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== ABOUT SECTION END ======-->
<!--====== SERVICES SECTION START ======-->
<section class="services-secton services-secton-three pt-120 pb-120">
    <div class="container">
        <!-- Section Title -->
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-5 col-md-8 col-sm-7">
                <div class="section-title left-border">
                    <span class="title-tag">our services</span>
                    <h2>see what we do here with good passions</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
                <div class="service-page-link- text-right">
                    <a routerLink='/services' class="main-btn btn-filled">learn more</a>
                </div>
            </div>
        </div>
        <div class="services-loop mt-50">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-service-two white-bg">
                        <div class="top-icon">
                            <i class="fal fa-long-arrow-right"></i>
                        </div>
                        <h4>IT Design</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incid.
                        </p>
                        <div class="bottom-icon">
                            <i class="pe-7s-diamond"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-service-two white-bg">
                        <div class="top-icon">
                            <i class="fal fa-long-arrow-right"></i>
                        </div>
                        <h4>IT management</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incid.
                        </p>
                        <div class="bottom-icon">
                            <i class="pe-7s-arc"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-service-two white-bg">
                        <div class="top-icon">
                            <i class="fal fa-long-arrow-right"></i>
                        </div>
                        <h4>IT Security</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incid.
                        </p>
                        <div class="bottom-icon">
                            <i class="pe-7s-refresh-2"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-service-two white-bg">
                        <div class="top-icon">
                            <i class="fal fa-long-arrow-right"></i>
                        </div>
                        <h4>Data Security</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incid.
                        </p>
                        <div class="bottom-icon">
                            <i class="pe-7s-world"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== SERVICES SECTION END ======-->
<!--====== FRAMEWORK PART START ======-->
<section class="framework-section padding-bottom-extra">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="framework-img">
                    <img src="assets/img/framework.jpg" alt="Image">
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class="framework-text pl-20">
                    <div class="section-title left-border mb-40">
                        <span class="title-tag">framework</span>
                        <h2> we are using best quality framwork for you. </h2>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade" id="python" role="tabpanel">
                            <p>
                                Over the years, a wide range of developments and innovations in the global IT arena
                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                is need for IT today, not just in urban areas but rural regions as well.
                            </p>
                        </div>
                        <div class="tab-pane fade show active" id="wordpress" role="tabpanel">
                            <p>
                                Over the years, a wide range of developments and innovations in the global IT arena
                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                is need for IT today, not just in urban areas but rural regions as well.
                            </p>
                        </div>
                        <div class="tab-pane fade" id="html" role="tabpanel">
                            <p>
                                Over the years, a wide range of developments and innovations in the global IT arena
                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                is need for IT today, not just in urban areas but rural regions as well.
                            </p>
                        </div>
                        <div class="tab-pane fade" id="java" role="tabpanel">
                            <p>
                                Over the years, a wide range of developments and innovations in the global IT arena
                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                is need for IT today, not just in urban areas but rural regions as well.
                            </p>
                        </div>
                    </div>
                    <ul class="framework-list nav nav-pills mt-25" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" id="pills-python-tab" data-toggle="pill" href="#python">
                                <span class="icon">
                                    <i class="fab fa-python"></i>
                                </span>
                                Python
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-wordpress-tab" data-toggle="pill"
                                href="#wordpress">
                                <span class="icon">
                                    <i class="fab fa-wordpress"></i>
                                </span>
                                Wordpress
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-html-tab" data-toggle="pill" href="#html">
                                <span class="icon">
                                    <i class="fab fa-html5"></i>
                                </span>
                                HTML5
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-java-tab" data-toggle="pill" href="#java" role="tab">
                                <span class="icon">
                                    <i class="fab fa-java"></i>
                                </span>
                                JAVA
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== FRAMEWORK PART end ======-->
<!--====== CONTERUP PART START ======-->
<section class="counter-section mt-negative">
    <div class="container">
        <div class="counter-inner">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="counter-box">
                        <h1><span class="counter">299</span><span>+</span></h1>
                        <p class="title">Project Done</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="counter-box">
                        <h1><span class="counter">177</span><span>+</span></h1>
                        <p class="title">Active Client</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="counter-box">
                        <h1><span class="counter">5</span><span>K+</span></h1>
                        <p class="title">Drink Coffee</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="counter-box">
                        <h1><span class="counter">69</span><span>K+</span></h1>
                        <p class="title">Award winning</p>
                    </div>
                </div>
            </div>
            <span class="big-text">
                Beyond Agility Solutions
            </span>
        </div>
    </div>
</section>
<!--====== CONTERUP PART END ======-->
<!--====== LATEST POST START ======-->
<section class="latetest-post pt-120 pb-90">
    <div class="container">
        <!-- Section  Title -->
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-8 col-sm-7 col-6">
                <div class="section-title left-border">
                    <span class="title-tag">Blog</span>
                    <h2>news feeds</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-5 col-6">
                <div class="blog-btn text-right">
                    <a routerLink='/blog' class="main-btn btn-filled">learn more</a>
                </div>
            </div>
        </div>
        <!-- post Loop -->
        <div class="post-loop mt-70">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-12 col-sm-10 order-2 order-lg-1">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>Software</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="post-grid-box mb-30">
                                <ul class="post-cat">
                                    <li><a routerLink='/blog-detail'>SAAS</a></li>
                                </ul>
                                <div class="post-desc">
                                    <h4><a routerLink='/blog-detail'>All these data frustrate the user and leads.</a>
                                    </h4>
                                    <p>Too much data and forcing the brain to accommodate all these data fru strate
                                        the user
                                        and leads them.</p>
                                </div>
                                <ul class="post-meta">
                                    <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                                    <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-10 col-sm-10 order-1 order-lg-2">
                    <div class="post-grid-box mb-30">
                        <ul class="post-cat">
                            <li><a routerLink='/blog-detail'>Business</a></li>
                        </ul>
                        <div class="post-desc">
                            <h4><a routerLink='/blog-detail'>Consider we have two screens of the ui app, the first
                                    screen has instructions.</a></h4>
                            <p>Too much data and forcing the brain to accommodate all these data frustrate the user
                                and leads them to abandon the task. The short term memory of our brain has
                                limitations.</p>
                        </div>
                        <ul class="post-meta">
                            <li><a routerLink='/blog-detail'><i class="fal fa-calendar-alt"></i>14th May 2020</a></li>
                            <li><a routerLink='/blog-detail'><i class="fal fa-comments"></i>10 Comments</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== LATEST POST END ======-->
<app-footer3></app-footer3>

