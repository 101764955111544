<app-header3></app-header3>
<!--====== BREADCRUMB SECTION START ======-->
<section class="breadcrumb-section" style="background-image: url(assets/img/87.jpg);">
    <div class="container">
        <div class="breadcrumb-text">
            <h1>IT Consulting services</h1>
            <p>Your Partner for Software Innovation</p>
        </div>
        <ul class="breadcrumb-nav">
            <li><a routerLink='/'>Home</a></li>
            <li>IT Consulting services</li>
        </ul>
        <span class="btg-text">Tek Assets</span>
    </div>
    <div class="breadcrumb-shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
            </path>
        </svg>
    </div>
</section>
<!--====== BREADCRUMB SECTION END ======-->

<section class="service-details-wrap pt-150 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="service-details">
                    <!-- <div class="thmb mb-50">
                        <img src="assets/img/arif-riyanto-vJP-wZ6hGBg-unsplash.jpg" alt="Image">
                    </div> -->
                    <div class="details-text mb-50">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">Consulting Services</span>
                            <!-- <h2>Dedicated IT Solutions with 17 Years Experience in this field.</h2> -->
                        </div>
                        <p class="mb-30">
                            Once a business owner defined the needs to take a business to the next
                            level, a decision maker will define a scope, cost and a time frame of the project.[1]
                            The role of the IT consultancy company is to support and nurture the company from the
                            very beginning of the project until the end, and deliver the project not only in the
                            scope, time and cost but also with complete customer satisfaction.
                        </p>
                        <p>
                            The scope of a project is linked intimately to the proposed business processes and
                            systems that the project is going to deliver. Regardless of whether the project is to
                            launch a new product range or discontinue unprofitable parts of the business, the change
                            will have some impact on business processes and systems. The documentation of your
                            business processes and system requirements are as fundamental to project scoping as an
                            architects plans would be to the costing and scoping of the construction of a building.
                            The most successful business projects are always those that are driven by an employee
                            who has the authority, vision and influence to drive the required changes in a business.
                            It is highly unlikely that a business owner (decision maker or similar) will realize the
                            changes unless one has one of these people in the employment. However, the project
                            leadership role typically requires significant experience and skills which are not
                            usually found within a company focused on day-to-day operations. Due to this requirement
                            within more significant business change projects/programs, outside expertise is often
                            sought from firms which can bring this specific skill set to the company.
                        </p>
                    </div>
                    <div class="feature-list-wrap mb-50">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">features</span>
                            <h2>Why Choose Us</h2>
                        </div>
                        <div class="feature-list">
                            <div class="row align-items-center">
                                <div class="col-sm-5">
                                    <div class="list-img">
                                        <img src="assets/img/arif-riyanto-vJP-wZ6hGBg-unsplash.jpg" alt="Image">
                                    </div>
                                </div>
                                <div class="col-sm-7">
                                    <div class="list-text">
                                        <p>The most successful business projects are always those that are driven by
                                            an employee who has the authority, vision and influence to drive the
                                            required changes in a business.</p>
                                        <ul>
                                            <li><i class="fal fa-check"></i> Advisory skills</li>
                                            <li><i class="fal fa-check"></i> Technical skills</li>
                                            <li><i class="fal fa-check"></i> Management skills</li>
                                            <!-- <li><i class="fal fa-check"></i> Business and management language</li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="faq-wrapper">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">faq</span>
                            <h2>get every answers here</h2>
                        </div>
                        <div class="faq-accordion faq-loop accordion" id="faqAccordion">
                            <div class="card">
                                <div class="card-header">
                                    <button type="button" data-toggle="collapse" data-target="#faqOne">
                                        Under normal circumstances a fee for IT consulting ?
                                        <span class="icon"><span>+</span></span>
                                    </button>
                                </div>
                                <div id="faqOne" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <button type="button" class="active-accordion" data-toggle="collapse"
                                        data-target="#faqtwo">
                                        Under normal circumstances a fee for IT consulting ?
                                        <span class="icon"><span>+</span></span>
                                    </button>
                                </div>
                                <div id="faqtwo" class="collapse show" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <button type="button" data-toggle="collapse" data-target="#faqThree">
                                        Under normal circumstances a fee for IT consulting ?
                                        <span class="icon"><span>+</span></span>
                                    </button>
                                </div>
                                <div id="faqThree" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <button type="button" data-toggle="collapse" data-target="#faqFour">
                                        Under normal circumstances a fee for IT consulting ?
                                        <span class="icon"><span>+</span></span>
                                    </button>
                                </div>
                                <div id="faqFour" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <button type="button" data-toggle="collapse" data-target="#faqFive">
                                        Under normal circumstances a fee for IT consulting ?
                                        <span class="icon"><span>+</span></span>
                                    </button>
                                </div>
                                <div id="faqFive" class="collapse" data-parent="#faqAccordion">
                                    <div class="card-body">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                        sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-8 col-sm-10">
                <app-servicesidebar></app-servicesidebar>
            </div> -->
        </div>
    </div>
</section>
<app-footer3></app-footer3>
