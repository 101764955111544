<!--====== GO TO TOP PART START ======-->
<div class="go-top-area">
    <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
            <div class="go-top go-top-btn">
                <i class="fal fa-angle-double-up"></i>
                <i class="fal fa-angle-double-up"></i>
            </div>
        </div>
    </div>
</div>
<!--====== GO TO TOP PART ENDS ======-->
<footer class="footer-style-three">
    <div class="footer-top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-sm-4">
                    <div class="logo text-center text-sm-left">
                        <a href="index.html">
                          <p>Tek Assets</p>
                        </a>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="social-icon text-center text-sm-right">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-behance"></i></a>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                        <a href="#"><i class="fab fa-youtube"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- widgets -->
<!--    <div class="footer-widget-area">-->
<!--        <div class="container">-->
<!--            <div class="row">-->
<!--                <div class="col-lg-2 col-md-6 col-sm-6">-->
<!--                    <div class="widget nav-widget d-flex justify-content-start">-->
<!--                        <div>-->
<!--                            <h5 class="widget-title">Company</h5>-->
<!--                            <ul>-->
<!--                                <li><a href="#">About</a></li>-->
<!--                                <li><a href="#">Leadership</a></li>-->
<!--                                <li><a href="#">Blog</a></li>-->
<!--                                <li><a href="#">Careers</a></li>-->
<!--                                <li><a href="#">Partners</a></li>-->
<!--                                <li><a href="#">Referral Program</a></li>-->
<!--                                <li><a href="#">Press</a></li>-->
<!--                                <li><a href="#">Legal</a></li>-->
<!--                            </ul>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 col-md-6 col-sm-6">-->
<!--                    <div class="widget nav-widget d-flex justify-content-start justify-content-lg-center">-->
<!--                        <div>-->
<!--                            <h5 class="widget-title">Community</h5>-->
<!--                            <ul>-->
<!--                                <li><a href="#">Tutorials</a></li>-->
<!--                                <li><a href="#">Meetups</a></li>-->
<!--                                <li><a href="#">Q&A</a></li>-->
<!--                                <li><a href="#">Write for DOnations</a></li>-->
<!--                                <li><a href="#">Droplets for Demos</a></li>-->
<!--                                <li><a href="#">Hatch Startup Program</a></li>-->
<!--                                <li><a href="#">Shop Swag</a></li>-->
<!--                                <li><a href="#">Research Program</a></li>-->
<!--                            </ul>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 col-md-6 col-sm-6">-->
<!--                    <div class="widget nav-widget d-flex justify-content-start justify-content-lg-center">-->
<!--                        <div>-->
<!--                            <h5 class="widget-title">Solutions</h5>-->
<!--                            <ul>-->
<!--                                <li><a href="#">Web & Mobile Apps</a></li>-->
<!--                                <li><a href="#">Website Hosting</a></li>-->
<!--                                <li><a href="#">Big Data</a></li>-->
<!--                                <li><a href="#">Managed Services</a></li>-->
<!--                                <li><a href="#">Business Solutions</a></li>-->
<!--                                <li><a href="#">Research Program</a></li>-->
<!--                                <li><a href="#">Currents Research</a></li>-->
<!--                                <li><a href="#">Open Source</a></li>-->
<!--                            </ul>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-lg-4 col-md-6 col-sm-6">-->
<!--                    <div class="widget recent-post">-->
<!--                        <div>-->
<!--                            <h5 class="widget-title">news feeds</h5>-->
<!--                            <div class="post-loop">-->
<!--                                <div class="post">-->
<!--                                    <div class="post-img">-->
<!--                                        <img src="assets/img/recent-post-wid/01.jpg" alt="Image">-->
<!--                                    </div>-->
<!--                                    <div class="post-desc">-->
<!--                                        <span class="time"><i class="fal fa-calendar-alt"></i> 14th May 2020</span>-->
<!--                                        <h5><a href="single-news.html">Managing Partner along with Senior-->
<!--                                                Counsels.</a></h5>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="post">-->
<!--                                    <div class="post-img">-->
<!--                                        <img src="assets/img/recent-post-wid/02.jpg" alt="Image">-->
<!--                                    </div>-->
<!--                                    <div class="post-desc">-->
<!--                                        <span class="time"><i class="fal fa-calendar-alt"></i> 14th May 2020</span>-->
<!--                                        <h5><a href="single-news.html">Managing Partner along with Senior-->
<!--                                                Counsels.</a></h5>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="post">-->
<!--                                    <div class="post-img">-->
<!--                                        <img src="assets/img/recent-post-wid/03.jpg" alt="Image">-->
<!--                                    </div>-->
<!--                                    <div class="post-desc">-->
<!--                                        <span class="time"><i class="fal fa-calendar-alt"></i> 14th May 2020</span>-->
<!--                                        <h5><a href="single-news.html">Managing Partner along with Senior-->
<!--                                                Counsels.</a></h5>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <!-- copyright -->
    <div class="copy-right-area">
        <div class="container">
        </div>
    </div>
</footer>
<!--====== FOOTER PART END ======-->

